import LoginForm from "../components/login-form";
import SEO from "../components/seo";
import AuthContainer from "../containers/auth";

function App() {
  return (
    <>
      <SEO
        url="/" 
        openGraphType="website"
        schemaType="website"
        title="Jobex Login"
        description="Jobex allows newbies without experience to find jobs"
      />
    <AuthContainer>
      <LoginForm />
    </AuthContainer>
    </>
  );
}

export default App;
