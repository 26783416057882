import { Link } from "react-router-dom";
import mysteryUser from "../images/mystery.jpeg"

const SIZE = {
  small: "h-8 w-8",
  medium: "h-10 w-10",
  large: "h-24 w-24",
}


export const UserPhoto = ({photo='', altText='user', slug='', size=SIZE.small}) => (
  <div className={`${SIZE[size]} overflow-hidden rounded-full flex justify-center items-top`} >
    { slug === '' ?
      <img
      className=""
      src={photo || mysteryUser}
      alt={altText}
    /> :
    <Link to={`/users/${slug}`} >
      <img
        src={
          photo ? 
          photo :
          mysteryUser
        }
        alt={altText}
      />
    </Link>
    }
  </div>
)