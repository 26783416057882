/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Button from "../../components/button";
import OnboardLayout from "../../components/layout/onboard";
import { useNavigate, useParams } from "react-router-dom";

import lArrow from "../../images/svg/larrow.svg";
import rArrow from "../../images/svg/rarrow.svg";
import { connect } from "react-redux";
import { updateUser, createJobGig, submitJobStatus } from "../../redux/actions";
import SuccessModal from "../../components/success-modal";

const styles = {
  container:
    "max-w-6xl mx-auto bg-white px-16 py-10 rounded-lg shadow-sm flex flex-col mb-8 text-[#4E5D78]",
  buttonSelect:
    "mt-4 shadow-lg flex w-[60%] justify-between items-center py-4 px-8 hover:bg-blue-500 hover:text-white rounded-lg cursor-pointer transition-all duration-300 mb-2",
};

function NewJob({ updateUser, userData, createJob, job, changeSubmitStat }) {
  const [jobTitle, setJobTitle] = useState("Product Design");
  const [hoursNeeded, setHoursNeeded] = useState(10);
  const [duration, setDuration] = useState("6 Months");
  const [skillLevel, setSkillLevel] = useState("Basic Knowledge");
  const [payment, setPayment] = useState("Free");
  const [stipendAmt, setStipendAmt] = useState("");
  const [workModel, setWorkModel] = useState("Remote");
  const [recruiter, setRecruiter] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [jobDesc, setJobDesc] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  let { type } = useParams();
  const { message, submitted } = job;
  const handleUpdate = () => {
    let newJob = {
      job: {
        recruiter_name: recruiter,
        title: jobTitle,
        duration: duration,
        hours_needed: hoursNeeded,
        content: jobDesc,
        payment: payment,
        work_model: workModel,
        skill_level: skillLevel,
        company_name: companyName,
        company_location: companyLocation,
        job_type: type,
      },
    };
    const {
      job: { recruiter_name, company_name, company_location, content },
    } = newJob;
    if (!!(recruiter_name && company_name && company_location && content)) {
      createJob(newJob);
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
  };
  useEffect(() => {
    if (message === "Job created" && submitted) {
      setStipendAmt("");
      setRecruiter("");
      setCompanyName("");
      setCompanyLocation("");
      setJobDesc("");
      setIsOpen(true);
    }
    setTimeout(() => {
      console.log(submitted);
    }, 3000);
  }, [job]);

  const onClose = () => {
    setIsOpen(false);
    changeSubmitStat(false);
  };

  const JOB_CATEGORY = [
    "Product Design",
    "UI/UX Designer",
    "Graphics Designer",
    "Product Manager",
    "Frontend Developer",
    "Backend Developer",
  ];

  const HOURS = [5, 10, 15, 20, 30];

  const DURATION = [
    { title: "1 Month", type: "gig" },
    { title: "2 Months", type: "gig" },
    { title: "3 Months", type: "both" },
    { title: "4 Months", type: "gig" },
    { title: "5 Months", type: "gig" },
    { title: "6 Months", type: "both" },
    { title: "7 Months", type: "gig" },
    { title: "8 Months", type: "gig" },
    { title: "9 Months", type: "gig" },
    { title: "10 Months", type: "gig" },
    { title: "11 Months", type: "gig" },
    { title: "1 Year Plus", type: "gig" },
    { title: "1 Year", type: "job" },
    { title: "Part Time", type: "job" },
    { title: "Full Time", type: "job" },
  ];

  const SKILL_LEVEL = [
    "Skilled Acquired Level",
    "No Skill Required",
    "Basic Knowledge",
  ];

  const WORK_MODEL = ["On Site", "Remote", "Hybrid"];

  const PAY_TYPE = ["Free", "Stipends"];

  return (
    <OnboardLayout>
      <div className="w-[90%] text-lg flex justify-between mx-auto mb-8 text-[#9C9C9C]">
        <button
          onClick={() => navigate("/onboarding/job")}
          className="flex cursor-pointer"
        >
          <img src={lArrow} alt="lArrow" />
          <span className="ml-2">Back</span>
        </button>
        <button
          onClick={() => navigate("/dashboard")}
          className="flex cursor-pointer"
        >
          <span className="mr-2">Skip</span>
          <img src={rArrow} alt="lArrow" />
        </button>
      </div>

      <div className={styles.container}>
        <h1 className="text-2xl font-bold text-[#4E5D78] mb-2 ">
          Post a <span className="capitalize">{type}</span>
        </h1>

        <div className="mt-4 w-full sm:w-[90%]">
          <h4>{type === "job" ? "Job Title" : "Gigs Category"}</h4>

          <div className="flex gap-2 flex-wrap my-4">
            {JOB_CATEGORY.map((cat) => (
              <Button
                handleClick={() => setJobTitle(cat)}
                key={cat}
                variant="normal"
                outline={true}
                className={`${
                  jobTitle === cat && "bg-blue-600 border-blue-600"
                } rounded-sm mt-0`}
              >
                <span
                  className={`${
                    jobTitle === cat && "text-white"
                  } text-sm font-light`}
                >
                  {cat}
                </span>
              </Button>
            ))}
          </div>

          <div className="flex flex-wrap gap-2 my-3">
            <div>
              <label className="block text-sm mb-2" htmlFor="recruiter">
                Recruiter Name
              </label>
              <input
                onChange={(e) => setRecruiter(e.target.value)}
                value={recruiter}
                id="recruiter"
                name="recruiter"
                className="w-full p-[10px] border border-gray-300 rounded-sm mb-4"
              />
            </div>

            <div>
              <label className="block text-sm mb-2" htmlFor="comanyName">
                Company Name
              </label>
              <input
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
                id="comanyName"
                name="comanyName"
                className="w-full p-[10px] border border-gray-300 rounded-sm mb-4"
              />
            </div>

            <div>
              <label className="block text-sm mb-2" htmlFor="companyLocation">
                Company Location
              </label>
              <input
                onChange={(e) => setCompanyLocation(e.target.value)}
                value={companyLocation}
                id="companyLocation"
                name="companyLocation"
                className="w-full p-[10px] border border-gray-300 rounded-sm mb-4"
              />
            </div>
          </div>

          {type === "gig" && (
            <div className="my-4">
              <h4 className="block text-sm mb-2">Hours Needed</h4>
              <div className="flex gap-2 flex-wrap">
                {HOURS.map((hour) => (
                  <Button
                    handleClick={() => setHoursNeeded(hour)}
                    key={hour}
                    variant="normal"
                    outline={true}
                    className={`${
                      hoursNeeded === hour && "bg-blue-600 border-blue-600"
                    } rounded-sm mt-0`}
                  >
                    <span
                      className={`${
                        hoursNeeded === hour && "text-white"
                      } text-sm font-light`}
                    >
                      {`${hour}Hours/Week`}
                    </span>
                  </Button>
                ))}
              </div>
            </div>
          )}

          <div className="my-4">
            <h4 className="block text-sm mb-1"> Duration </h4>
            <div className="flex gap-2 flex-wrap">
              {DURATION.map(
                (dura) =>
                  (dura.type === type || dura.type === "both") && (
                    <Button
                      handleClick={() => setDuration(dura.title)}
                      key={dura.title}
                      variant="normal"
                      outline={true}
                      className={`${
                        duration === dura.title && "bg-blue-600 border-blue-600"
                      } rounded-sm mt-0`}
                    >
                      <span
                        className={`${
                          duration === dura.title && "text-white"
                        } text-sm font-light`}
                      >
                        {dura.title}
                      </span>
                    </Button>
                  )
              )}
            </div>
          </div>

          <div className="my-4">
            <h4 className="block text-sm mb-1">Skill Level </h4>
            <div className="flex gap-2 flex-wrap">
              {SKILL_LEVEL.map((skill) => (
                <Button
                  handleClick={() => setSkillLevel(skill)}
                  key={skill}
                  variant="normal"
                  outline={true}
                  className={`${
                    skillLevel === skill && "bg-blue-600 border-blue-600"
                  } rounded-sm mt-0`}
                >
                  <span
                    className={`${
                      skillLevel === skill && "text-white"
                    } text-sm font-light`}
                  >
                    {skill}
                  </span>
                </Button>
              ))}
            </div>
          </div>

          <div className="my-4">
            <label className="block text-sm mb-2" htmlFor="jobDesc">
              Job Description
            </label>
            <textarea
              onChange={(e) => setJobDesc(e.target.value)}
              value={jobDesc}
              id="jobDesc"
              className="w-full p-[1rem] border border-gray-300 rounded-sm h-48"
              name="jobDesc"
            />
          </div>

          <div className="flex gap-8">
            <div className="my-4">
              <h4 className="block text-sm mb-1">Payment </h4>
              <div className="flex gap-2 flex-wrap">
                {PAY_TYPE.map((pay) => (
                  <Button
                    handleClick={() => setPayment(pay)}
                    key={pay}
                    variant="normal"
                    outline={true}
                    className={`${
                      payment === pay && "bg-blue-600 border-blue-600"
                    } rounded-sm mt-0`}
                  >
                    <span
                      className={`${
                        payment === pay && "text-white"
                      } text-sm font-light`}
                    >
                      {pay}
                    </span>
                  </Button>
                ))}
                <input
                  onChange={(e) => setStipendAmt(e.target.value)}
                  value={stipendAmt}
                  name="stipendAmt"
                  className="p-[10px] border border-gray-300 rounded-sm"
                  size={8}
                  placeholder="$"
                />
              </div>
            </div>

            <div className="my-4">
              <h4 className="block text-sm mb-1">Working Model </h4>
              <div className="flex gap-2 flex-wrap">
                {WORK_MODEL.map((work) => (
                  <Button
                    handleClick={() => setWorkModel(work)}
                    key={work}
                    variant="normal"
                    outline={true}
                    className={`${
                      workModel === work && "bg-blue-600 border-blue-600"
                    } rounded-sm mt-0`}
                  >
                    <span
                      className={`${
                        workModel === work && "text-white"
                      } text-sm font-light`}
                    >
                      {work}
                    </span>
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <div className="my-8 space-x-2">
            <Button
              handleClick={() => {}}
              variant="normal"
              outline={true}
              className="bg-blue-600 border-blue-600 rounded-sm mt-0"
            >
              <span className="text-white text-sm font-light">
                Post <span className="uppercase">{type}</span>
              </span>
            </Button>
            <Button
              handleClick={() => {}}
              variant="normal"
              outline={true}
              className="border-blue-600 rounded-sm mt-0"
            >
              <span className="text-sm font-light text-blue-600 hover:text-white">
                Save as Draft
              </span>
            </Button>
            <Button
              handleClick={() => {}}
              variant="normal"
              outline={true}
              className="border-blue-600 rounded-sm mt-0"
            >
              <span className="text-sm font-light text-blue-600 hover:text-white">
                Discard/Close
              </span>
            </Button>
          </div>
        </div>

        <Button handleClick={handleUpdate} className="rounded-sm">
          Continue
        </Button>
        <p
          className={`${
            displayError ? "flex" : "hidden"
          } mt-6 justify-center text-red-600`}
        >
          Kindly select and fill all fields above
        </p>
      </div>
      <div className="h-4" />
      {isOpen && (
        <SuccessModal
          message={`${
            type.charAt(0).toUpperCase() + type.slice(1)
          } submitted successfully`}
          background="bg-[#E8ECFF]"
          btnStyle="bg-[#FFF7DC]"
          onClose={onClose}
        />
      )}
    </OnboardLayout>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  createJob: (data) => dispatch(createJobGig(data)),
  changeSubmitStat: (stat) => dispatch(submitJobStatus(stat)),
});

const mapStateToProps = (state) => ({
  userData: state.user,
  job: state.job,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewJob);
