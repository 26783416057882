

import { useNavigate } from "react-router-dom";
import AccountConfirm from "../components/auth-confirm";
import AuthContainer from "../containers/auth";

export default function ThankYou() {
  const navigate = useNavigate();

  return (
    <AuthContainer>
      <AccountConfirm
        title="Thank you for confirming your email"
        btnText="Go to login"
        handleClick={() => navigate("/")}
       />
    </AuthContainer>
  )
}
