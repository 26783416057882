import Button from "../../button";
import Modal from "../../modal";
import { useState, useEffect } from "react";
import { AiOutlineCloseCircle, AiOutlineClose } from "react-icons/ai";
import { UserPhoto } from "../../../utils/userPhoto";
import coverImg from "../../../images/profile/cover_default.jpg";
import { updateUser } from "../../../redux/actions";
import { connect } from "react-redux";
import ImageUploader from "../../../utils/uploadImage";

import camImg from "../../../images/svg/camera.svg";

function EditProfile({ userData, updateUser, onClose, onSubmit }) {
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [language, setLanguage] = useState([]);
  const [tempLang, setTempLang] = useState("");
  const [bio, setBio] = useState("");
  const [languageFocus, setLanguageFocus] = useState(false);
  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [bioFocus, setBioFocus] = useState(false);
  const [skills, setSkills] = useState([]);
  const [photo, setPhoto] = useState("");
  const [coverImage, setCoverImage] = useState("");

  const nameLength = 15;
  const languageLength = 15;
  const bioLength = 500;
  const skillsLength = 30;

  const cleanUpStates = () => {
    setFirstName("");
    setLastName("");
    setLanguage([]);
    setBio("");
    setSkills([]);
    setPhoto("");
    setCoverImage("");
  };

  useEffect(() => {
    if (userData?.currentUser) {
      setIsOpen(true);
      let fullName = userData.currentUser.full_name.split(" ");

      setFirstName(fullName[0]);
      setLastName(fullName[1]);
      setBio(userData.currentUser.bio);
      setSkills(userData.currentUser.skills);
      setLanguage(userData.currentUser.language);
      setPhoto(userData.currentUser.photo);
      setCoverImage(userData.currentUser.cover_image);
    }
  }, []);

  const addSkills = (event) => {
    if (event.key === "Enter") {
      let skill = event.target.value;
      event.target.value = "";
      setSkills((prevSkill) => [...prevSkill, skill]);
    }
  };

  const removeSkill = (skill) => {
    let arr = skills.filter((s) => s !== skill);
    setSkills(arr);
  };

  const addLanguages = (event) => {
    if (event.key === "Enter") {
      let lang = event.target.value;
      event.target.value = "";
      setTempLang("");
      setLanguage((prevLanguage) => [...prevLanguage, lang]);
    }
  };

  const removeLanguage = (lang) => {
    let arr = language.filter((l) => l !== lang);
    setLanguage(arr);
  };

  const closeModal = () => {
    setIsOpen(false);
    onClose();
  };

  const handleImageUpload = (e) => {
    const imgInput = document.getElementById("imgupload");
    imgInput.click();
  };

  const handleImageChange = async (e) => {
    const image = e.target.files[0];
    const imageLink = await ImageUploader(image);
    setPhoto(imageLink.url);
    updateUser({ photo: imageLink.url });
  };

  const handleCoverImageChange = async (e) => {
    const image = e.target.files[0];
    const imageLink = await ImageUploader(image);
    setCoverImage(imageLink.url);
    updateUser({ cover_image: imageLink.url });
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="inline-block relative align-bottom bg-white rounded-lg text-left overflow-y-scroll shadow-xl transform transition-all sm:align-middle sm:h-[90vh] sm:w-[50vw]">
        <div className="h-[13rem] p-1 rounded-t-lg">
          <div
            className="h-full relative rounded-t-lg"
            style={{ backgroundImage: `url(${coverImage || coverImg})` }}
          >
            <input
              onChange={handleCoverImageChange}
              type="file"
              id="coverImage"
              className="hidden"
            />
            <button
              onClick={() => {
                const imgInput = document.getElementById("coverImage");
                imgInput.click();
              }}
              className="mr-1 absolute left-[50%] top-[50%]"
            >
              <img src={camImg} alt="coverImage" />
            </button>
            <div className="absolute z-10 -bottom-10 left-10 flex items-center">
              <UserPhoto
                photo={photo}
                altText={userData.currentUser?.owner}
                slug={userData.currentUser?.slug}
                size="large"
              />
              <input
                onChange={handleImageChange}
                type="file"
                id="imgupload"
                className="hidden"
              />
              <button
                onClick={handleImageUpload}
                className="mr-1 absolute left-8"
              >
                <img src={camImg} className="h-8 w-8" alt={"edit"} />
              </button>
            </div>
            <div className="w-full z-0 h-10 absolute -bottom-10 bg-white"></div>
          </div>
        </div>
        <div className="absolute top-3 right-3">
          <button className="text-[#fff]" onClick={closeModal}>
            <AiOutlineCloseCircle />
          </button>
        </div>
        <div className="bg-white px-4 sm:px-8 pt-6 pb-6 mt-[2rem] sm:pt-[1rem] sm:pb-[1rem]">
          <div
            className={`px-4 text-[#2d2d2d] flex flex-col py-2 rounded mt-3 border-[1px] ${
              firstNameFocus ? "border-[#0066F5]" : "border-[#CFCFCF]"
            }`}
          >
            <div className="flex justify-between items-center sm:text-[15px]">
              <label
                className={`${
                  firstNameFocus ? "text-[#0066F5]" : "text-[#CFCFCF]"
                }`}
                htmlFor="edit-first-name"
              >
                First Name
              </label>
              <span className="text-[#CFCFCF]">
                {firstName.length}/{nameLength}
              </span>
            </div>
            <div>
              <input
                className="focus:outline-none font-semibold text-[#4E5D78] text-lg w-full"
                type="text"
                id="edit-first-name"
                name="editFirstName"
                placeholder="First Name"
                value={firstName}
                maxLength={nameLength}
                onFocus={() => setFirstNameFocus(true)}
                onBlur={() => setFirstNameFocus(false)}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>

          <div
            className={`px-4 text-[#2d2d2d] flex flex-col py-2 rounded mt-3 border-[1px] ${
              lastNameFocus ? "border-[#0066F5]" : "border-[#CFCFCF]"
            }`}
          >
            <div className="flex justify-between items-center sm:text-[15px]">
              <label
                className={`${
                  lastNameFocus ? "text-[#0066F5]" : "text-[#CFCFCF]"
                }`}
                htmlFor="edit-last-name"
              >
                Last Name
              </label>
              <span className="text-[#CFCFCF]">
                {lastName.length}/{nameLength}
              </span>
            </div>
            <div>
              <input
                className="focus:outline-none font-semibold text-[#4E5D78] text-lg w-full"
                type="text"
                id="edit-last-name"
                name="editLastName"
                placeholder="Last Name"
                value={lastName}
                maxLength={nameLength}
                onFocus={() => setLastNameFocus(true)}
                onBlur={() => setLastNameFocus(false)}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div
            className={`px-4 text-[#2d2d2d] flex flex-col py-2 rounded mt-5 border-[1px] ${
              bioFocus ? "border-[#0066F5]" : "border-[#CFCFCF]"
            }`}
          >
            <div className="flex justify-between items-center sm:mb-4 sm:text-[15px]">
              <label
                className={`${bioFocus ? "text-[#0066F5]" : "text-[#CFCFCF]"}`}
                htmlFor="edit-name"
              >
                bio
              </label>
              <span className="text-[#CFCFCF]">
                {bio.length}/{bioLength}
              </span>
            </div>
            <div>
              <textarea
                className="focus:outline-none font-medium text-[#4E5D78] sm:text-[17px] h-[80px] max-h-[100px] w-full"
                id="edit-bio"
                name="editBio"
                placeholder="Enter bio"
                value={bio}
                maxLength={bioLength}
                onFocus={() => setBioFocus(true)}
                onBlur={() => setBioFocus(false)}
                onChange={(e) => setBio(e.target.value)}
              />
            </div>
          </div>

          <div
            className={`px-4 text-[#2d2d2d] flex flex-col py-2 rounded mt-5 border-[1px] ${
              languageFocus ? "border-[#0066F5]" : "border-[#CFCFCF]"
            }`}
          >
            <div className="flex justify-between items-center sm:text-[15px]">
              <label
                className={`${
                  languageFocus ? "text-[#0066F5]" : "text-[#CFCFCF]"
                }`}
                htmlFor="edit-name"
              >
                Language
              </label>
              <span className="text-[#CFCFCF]">
                {tempLang.length}/{languageLength}
              </span>
            </div>
            <div>
              <input
                className="focus:outline-none text-[#4E5D78] py-2 text-[17px] w-full"
                type="text"
                id="edit-language"
                name="editLanguage"
                placeholder="add a language and press enter"
                maxLength={languageLength}
                onChange={(e) => setTempLang(e.target.value)}
                onFocus={() => setLanguageFocus(true)}
                onBlur={() => setLanguageFocus(false)}
                onKeyDown={(e) => addLanguages(e)}
              />
            </div>
          </div>
          <div className="flex flex-wrap text-[#565656] sm:gap-x-4 sm:gap-y-2  mt-3">
            {language?.map((lang, index) => (
              <div
                key={index}
                className="px-5 py-2 rounded-[20px] text-[15px] flex gap-x-2 items-center border-[1px] border-[#818181]"
              >
                <p className="">{lang}</p>
                <span
                  onClick={() => removeLanguage(lang)}
                  className="cursor-pointer text-[12px]"
                >
                  <AiOutlineClose />
                </span>
              </div>
            ))}
          </div>
          <div className="mt-5">
            <label className="text-[#4E5D78] text-[20px]">Add skills</label>
            <hr className="mt-5"></hr>
            <p className="mt-2 text-[15px] text-[#565656]">
              suggested skills based on your profile
            </p>
            <div className="mt-1">
              <input
                className="focus:outline-none focus:border-[#0066F5] focus:border-0 focus:ring-[1px] focus:ring-[#0066F5] rounded px-4 py-3 border-[1px] border-[#CFCFCF] text-[#000] text-[17px] w-full"
                type="text"
                id="edit-skills"
                name="editskills"
                placeholder="add a skill and press enter"
                maxLength={skillsLength}
                onKeyDown={(e) => addSkills(e)}
              />
            </div>
            <div className="flex flex-wrap text-[#565656] sm:gap-x-4 sm:gap-y-2  mt-3">
              {skills?.map((skill, index) => (
                <div
                  key={index}
                  className="px-5 py-2 rounded-[20px] text-[15px] flex gap-x-2 items-center border-[1px] border-[#818181]"
                >
                  <p className="">{skill}</p>
                  <span
                    onClick={() => removeSkill(skill)}
                    className="cursor-pointer text-[12px]"
                  >
                    <AiOutlineClose />
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full justify-center px-4 py-6  space-x-4">
            <Button
              handleClick={() => {
                onSubmit({
                  first_name: firstName,
                  last_name: lastName,
                  bio,
                  language: language,
                  skills: skills,
                });
                cleanUpStates();
              }}
            >
              Submit
            </Button>
            <Button outline={true} onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  userData: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
