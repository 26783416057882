
// export const URL= process.env.NODE_ENV === 'production' ? 
//                 'https://jobex-api-e4635db75367.herokuapp.com/api/v1' : 
//                 'http://localhost:3001/api/v1';

export const URL = 'https://jobex-api-e4635db75367.herokuapp.com/api/v1'

export const LOCAL_STORAGE_KEY='jobex-user-token'

export const getLocalStorageValue = () => {
  if(typeof window !== 'undefined') {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
  }
}