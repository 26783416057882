import { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { UserPhoto } from "../../utils/userPhoto";
import Modal from "../modal";
import Button from "../button";

import locationImg from "../../images/svg/location.svg";
import emoticonImg from "../../images/post-modal/emoticon.svg";
import gifImg from "../../images/post-modal/gif.svg";
import imageImg from "../../images/post-modal/imgupload.svg";
import videoImg from "../../images/post-modal/video.svg";
import { toast } from "react-toastify";

const POST_ICONS = [
  {
    name: 'emoticon',
    icon: emoticonImg,
  },
  {
    name: 'gif',
    icon: gifImg,
  },
  {
    name: 'image',
    icon: imageImg,
  },
  {
    name: 'video',
    icon: videoImg,
  }
]


export default function PostModal({user={}, onClose, onSubmit}) {
    const [isOpen, setIsOpen] = useState(false);
    const [userPost, setUserPost] = useState('');
    const [userPrivacy, setUserPrivacy] = useState('general');

    useEffect(() => {
        if(!!user) {
          setIsOpen(true);
        }
    }, [])

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    }

    return (
      <Modal isOpen={isOpen}>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="flex justify-between">
                  <h3 className="mb-5 text-lg font-bold leading-6 text-gray-900" id="modal-title">
                      Create Post
                  </h3>
                  <button
                    onClick={closeModal}
                  >
                    <AiOutlineCloseCircle />
                  </button>
                  </div>
                  <hr className="mb-5" />

                  <div className="flex flex-row">
                    <div className="h-12 w-12 overflow-hidden mr-2">
                      <UserPhoto
                        photo={user.photo}
                        altText={user.name}
                        slug={user.slug}
                      />
                    </div>
                    <div className="flex flex-col">
                      <h4>{user?.full_name || 'John Doe'}</h4>
                      <div className="w-[5.8rem] px-1">
                        <select
                          onChange={({target: {value}}) => setUserPrivacy(value)} 
                          className="w-full text-[.8rem] border-[1px] rounded-full px-2">
                          <option value="general">Public</option>
                          <option value="followers">Friends Only</option>
                          <option value="only_me">Private</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mt-1 relative rounded-md">
                      <textarea                
                      onChange={({target: {value}}) => setUserPost(value)}
                      type="text"
                      name="post"
                      placeholder={`What's on your mind, ${'John'}?`}
                      className="disabled:opacity-50 w-[26rem] h-40 mb-1 block pl-7 p-4 sm:text-md border-white rounded-md"
                      />
                  </div>

                </div>
              </div>
            </div>

            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-col border-2 border-blue-100">
              <div className="px-4 py-4 sm:px-6 sm:flex border-[1px] border-blue-500 rounded-md w-full">
                <div className="flex justify-between w-full">
                  <div className="flex items-center">
                    <img
                      src={locationImg}
                      alt="location"
                      className="h-6 w-6"
                    />
                    <span className="text-gray-300 ml-4">Add Location</span>
                  </div>

                  <div className="flex justify-between w-[7rem]">
                    {
                      POST_ICONS.map(({name, icon}) => (
                        <img
                          key={name}
                          src={icon}
                          alt="emoticon"
                          className="h-6 w-6"
                        />
                      ))
                    }
                  </div>
                </div>
              </div>
              <Button
              onClick={() => {
                if(!userPost) {
                  toast.error('Please enter a post')
                  return
                }
                onSubmit({post: {content: userPost, privacy: userPrivacy}});
                closeModal();
              }}
              >Post</Button>
            </div>
      </div>
      </Modal>
    )
}