import { FaMapMarkerAlt, FaShareAlt, FaRegCalendar } from "react-icons/fa";
import jobImg from "../../images/svg/job-img.svg";
import jobImg2 from "../../images/svg/job-img2.svg";
import coyLogo from "../../images/svg/coy-logo.svg";
import coyLogo2 from "../../images/svg/coy-logo2.svg";
import Button from "../../components/button";
// import { useSelector } from "react-redux";

const JobDetails = ({job={}}) => {

    return (
        <div className="flex flex-col basis-[49%]">
          <div className="flex flex-col shadow-sm rounded-lg px-10 py-10 bg-white">
            <div className="space-x-2 text-[12px] font-light">
              <Button variant="normal" active={true}>
                Jobs
              </Button>
              <Button variant="normal" outline="true">
                Gigs
              </Button>
              <Button variant="normal" active={!!job.work_model} outline="true">
                Remote
              </Button>
              <Button variant="normal" outline="true">
                Date Posted
              </Button>
              <Button variant="normal" outline="true">
                Job Length
              </Button>
            </div>

            <div>
              <h2 className="text-xl mt-3 text-[#3A3A3A]">{job.title} </h2>
              <h3 className="text-sm text-[#4E5D78]">{job.company_name}</h3>
            </div>

            <div className="space-x-2 text-[10px] text-[#647189] font-light mt-6">
              <div className="inline-flex items-center bg-[#F8F8F8] p-1 rounded-md">
                <FaRegCalendar />
                <span className="ml-1">Full time</span>
              </div>
              <div className="inline-flex items-center bg-[#F8F8F8] p-1 rounded-md">
                <FaMapMarkerAlt />
                <span className="ml-1">{job.work_model || 'Remote'}</span>
              </div>
              <div className="inline-flex bg-[#F8F8F8] p-1 rounded-md">
                <span>product</span>
              </div>
              <div className="inline-flex items-center bg-[#F8F8F8] p-1 rounded-md">
                <FaShareAlt />
                <span className="ml-1">Share this job</span>
              </div>
            </div>

          <div>
            <div className="mt-3 text-[#A4ADBC] flex">
              <p className="mr-3">Duration:</p><span>{job.duration}</span>
            </div>
            <div className="mt-3 text-[#A4ADBC] flex">
              <p className="mr-3">Job Pay:</p><span>{job.payment}</span>
            </div>
            <div className="mt-3 text-[#A4ADBC] flex">
              <p className="mr-3">Skills Level:</p><span>{job.skill_level}</span>
            </div>
          </div>
            

            <div className="my-4 leading-7">
              <h2 className="text-xl text-[#4E5D78]">Job description</h2>
            </div>

            <div className="text-[#9aa4b4] leading-8">
              <p className="text-sm font-light">
                {job.description}
              </p>
            </div>
          </div>
          <div className="bg-white w-full rounded-md p-8 mt-5">
            <h4 className="mb-4">Similar Jobs</h4>
            <div className="flex flex-col sm:flex-row space-x-4">
              <div className="flex justify-between flex-col basis-[49%] rounded-md overflow-hidden shadow-md">
                <div>
                  <img
                    src={jobImg}
                    alt="ui/ux"
                    className="rounded-tr-md rounded-tl-md w-full"
                  />
                </div>
                <div className="mt-4 p-4 flex space-x-4 items-center">
                  <img src={coyLogo} alt="logo" />
                  <div className="leading-2">
                    <h4 className="-mb-2">UI/UX Intern</h4>
                    <span className="text-[#0049D7] text-[12px]">
                      FlutterWave Inc
                    </span>
                  </div>
                </div>
                <p className="text-[10px] text-[#B0B7C3] px-4 pb-4">
                  You will work closely and collaboratively with other teams,
                  stakeholders, and designers across the organization to achieve
                  these goals
                </p>
              </div>

              <div className="flex justify-between flex-col basis-[49%] rounded-md overflow-hidden shadow-md">
                <div>
                  <img
                    src={jobImg2}
                    alt="ui/ux"
                    className="rounded-tr-md rounded-tl-md w-full"
                  />
                </div>
                <div className="mt-4 p-4 flex space-x-4 items-center">
                  <img src={coyLogo2} alt="logo" />
                  <div>
                    <h4 className="-mb-2">UI/UX Intern</h4>
                    <span className="text-[#0049D7] text-[12px]">
                      FlutterWave Inc
                    </span>
                  </div>
                </div>
                <p className="text-[10px] text-[#B0B7C3] px-4 pb-4">
                  You will work closely and collaboratively with other teams,
                  stakeholders, and designers across the organization to achieve
                  these goals
                </p>
              </div>
            </div>
          </div>
        </div>
    )

}

export default JobDetails;

