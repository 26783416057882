import JobActionTypes from "./job.types";

const INITIAL_STATE = {
  allJobs: [],
  allGigs: [],
  error: null,
  loading: false,
  myJob: [],
  message: null,
  myGig: [],
  submitted: false,
}

const jobReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JobActionTypes.GET_JOBS_START:
    case JobActionTypes.GET_GIGS_START:
    case JobActionTypes.GET_MY_JOBS_START:
    case JobActionTypes.GET_MY_GIGS_START:
      return {
        ...state,
        loading: true,
      }
    case JobActionTypes.CREATE_JOB_GIG_START:
    case JobActionTypes.JOB_APPLY_START:
      return {
        ...state,
        loading: true,
        submitted:false,
        message: null,
      }
    case JobActionTypes.GET_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        allJobs: action.payload,
      }
    case JobActionTypes.GET_GIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        allGigs: action.payload,
      }
    case JobActionTypes.GET_JOBS_FAILURE:
    case JobActionTypes.GET_GIGS_FAILURE:
    case JobActionTypes.CREATE_JOB_GIG_FAILURE:
    case JobActionTypes.JOB_APPLY_FAILURE:
    case JobActionTypes.GET_MY_JOBS_FAILURE:
    case JobActionTypes.GET_MY_GIGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case JobActionTypes.CREATE_JOB_GIG_SUCCESS:
    case JobActionTypes.JOB_APPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: null,
      }
    case JobActionTypes.GET_MY_GIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        myGig: action.payload,
        error: null,
      }
    case JobActionTypes.GET_MY_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        myJob: action.payload,
        error: null,
      }
    case JobActionTypes.SUBMIT_STATUS:
      return {
        ...state,
        submitted: action.payload,
      }
    default:
      return state;
  }
}

export default jobReducer;