import JobActionTypes from "./job.types";
import axios from "axios";
import { toast } from "react-toastify";

export const getJobsStart = () => ({
    type: JobActionTypes.GET_JOBS_START,
  });
  
  export const getJobsSuccess = (jobs) => ({
    type: JobActionTypes.GET_JOBS_SUCCESS,
    payload: jobs
  });
  
  export const getJobsFailure = (error) => ({
    type: JobActionTypes.GET_JOBS_FAILURE,
    payload: error
  });

  export const getGigsStart = () => ({
    type: JobActionTypes.GET_GIGS_START,
  });
  
  export const getGigsSuccess = (Gigs) => ({
    type: JobActionTypes.GET_GIGS_SUCCESS,
    payload: Gigs
  });
  
  export const getGigsFailure = (error) => ({
    type: JobActionTypes.GET_GIGS_FAILURE,
    payload: error
  });

  export const createJobGigStart = () => ({
    type: JobActionTypes.CREATE_JOB_GIG_START,
  });
  
  export const createJobGigSuccess = (jobGig) => ({
    type: JobActionTypes.CREATE_JOB_GIG_SUCCESS,
    payload: jobGig
  });
  
  export const createJobGigFailure = (error) => ({
    type: JobActionTypes.CREATE_JOB_GIG_FAILURE,
    payload: error
  });

export const jobApplyStart = () => ({
    type: JobActionTypes.JOB_APPLY_START,
  });
  
  export const jobApplySuccess = (jobGig) => ({
    type: JobActionTypes.JOB_APPLY_SUCCESS,
    payload: jobGig
  });
  
  export const jobApplyFailure = (error) => ({
    type: JobActionTypes.JOB_APPLY_FAILURE,
    payload: error
  });

  export const getMyJobsStart = () => ({
    type: JobActionTypes.GET_MY_JOBS_START,
  });

  export const getMyJobsSuccess = (myJobs) => ({
    type: JobActionTypes.GET_MY_JOBS_SUCCESS,
    payload: myJobs
  });
  
  export const getMyJobsFailure = (error) => ({
    type: JobActionTypes.GET_MY_JOBS_FAILURE,
    payload: error
  });
  
  export const getMyGigsStart = () => ({
    type: JobActionTypes.GET_MY_GIGS_START,
  });

  export const submitJobStatus = (status) => ({
    type: JobActionTypes.SUBMIT_STATUS,
    payload: status
  });

  export const getMyGigsSuccess = (myGigs) => ({
    type: JobActionTypes.GET_MY_GIGS_SUCCESS,
    payload: myGigs
  });
  
  export const getMyGigsFailure = (error) => ({
    type: JobActionTypes.GET_MY_GIGS_FAILURE,
    payload: error
  });


  export const getJobs = () => function(dispatch) {
    dispatch(getJobsStart());
    axios
      .get("/jobs/all_jobs")
      .then(res => {
        dispatch(getJobsSuccess(res.data));
      })
      .catch(err => {
        dispatch(getJobsFailure(err.response.data.error));
      });
  };

  export const getMyJobs = () => function(dispatch) {
    dispatch(getMyJobsStart());
    axios
      .get("/jobs/my_jobs")
      .then(res => {
        dispatch(getMyJobsSuccess(res.data));
      })
      .catch(err => {
        dispatch(getMyJobsFailure(err.response.data.error));
      });
  };

  export const getMyGigs = () => function(dispatch) {
    dispatch(getMyGigsStart());
    axios
      .get("/jobs/my_gigs")
      .then(res => {
        dispatch(getMyGigsSuccess(res.data));
      })
      .catch(err => {
        dispatch(getMyGigsFailure(err.response.data.error));
      });
  };


  export const getGigs = () => function(dispatch) {
    dispatch(getGigsStart());
    axios
      .get("/jobs/all_gigs")
      .then(res => {
        dispatch(getGigsSuccess(res.data));
      })
      .catch(err => {
        dispatch(getGigsFailure(err.response.data.error));
      });
  };

  export const createJobGig = (data) => (dispatch) => {
    dispatch(createJobGigStart());
    axios
      .post("/jobs", data)
      .then(res => {
        dispatch(createJobGigSuccess(res.data));
        if(data?.job?.job_type?.toLowerCase()==='job'){
            dispatch(getJobs());
            dispatch(getMyJobs());
            toast.success("Job created successfully");
        }else if (data?.job?.job_type?.toLowerCase()==='gig'){
            dispatch(getGigs());
            dispatch(getMyGigs());
            toast.success("Gig created successfully");
        }
        dispatch(submitJobStatus(true));
      })
      .catch(err => {
        if(err.response.status === 401) {
          dispatch(createJobGigFailure(err.response?.data?.error));
          toast.error(err.response?.data?.error);
        } else {
          dispatch(createJobGigFailure("Something went wrong"));
          toast.error("Something went wrong");
        }
      });
  }

  export const applyJob = (data,jobtype) => function(dispatch) {
    dispatch(jobApplyStart());
    axios
      .post("/jobs/apply", data)
      .then(res => {
        dispatch(jobApplySuccess(res.data));
        if(jobtype.toLowerCase()==='job'){
            dispatch(getMyJobs());
        }else if(jobtype.toLowerCase()==='gig'){
            dispatch(getMyGigs());
        }
        toast.success(`successfully applied to a ${jobtype}`);
            
      })
      .catch(err => {
        if(err.response.status === 401) {
          dispatch(createJobGigFailure(err.response?.data?.error));
          toast.error(err.response?.data?.error);
        } else {
          dispatch(createJobGigFailure("Something went wrong"));
          toast.error("Something went wrong");
        }
      });
  }