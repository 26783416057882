import upgradeImg from '../../images/upgrade.png'
import { UserPhoto } from '../../utils/userPhoto'

export default function DashboardLeft({userInfo={}}) {
  return (
    <>
      {/* <div className='bg-white p-4 mr-4 rounded-lg mb-4 shadow-sm'>
        <div className='flex justify-between items-center'>
          <div className="h-16 w-16 overflow-hidden">
            <UserPhoto
                photo={userInfo.photo}
                altText={userInfo.name}
                slug={userInfo.slug}
              />
          </div>
          <div className='flex flex-col'>
            <h4 className='text-md font-bold'>{userInfo?.full_name || 'John Doe'}</h4>
            <span className='text-sm text-gray-400'>{userInfo?.title || 'Developer'}</span>
          </div>
        </div>
        <hr className='border border-gray-50 my-6' />
        <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-col'>
            <span>{userInfo?.followers || 0}</span>
            <span>Followers</span>
          </div>
          <div className='flex flex-col'>
            <span>{userInfo?.views || 0}</span>
            <span>Views</span>
          </div>
        </div>
      </div> */}

      <div className='bg-white overflow-hidden pl-4 mr-4 rounded-lg shadow-sm'>
        <div className='flex flex-row justify-between items-center'>
          <div>
            <h2 className='font-bold'>Upgrade to PRO</h2>
            <p className='text-gray-600'>Everything looks <br/>fine down here.</p>
          </div>

          <div className='flex'>
            <img
              src={upgradeImg}
              alt="upgrade"
            />
          </div>
        </div>
      </div>
    </>
  )
}