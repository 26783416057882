import React from 'react'
import DashboardLayout from '../../components/layout/dashboard'
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '../../components/button';
import farmCartImg from '../../images/svg/farm-cart.svg';
import timeImg from '../../images/svg/time.svg';
import bagImg from '../../images/svg/bag.svg';
import bagBigImg from '../../images/svg/bag-big.svg';
import jobImg from '../../images/svg/job-img.svg';
import jobImg2 from '../../images/svg/job-img2.svg';
import coyLogo from '../../images/svg/coy-logo.svg';
import coyLogo2 from '../../images/svg/coy-logo2.svg';
import PreLoader from '../../components/loader';
import { applyJob } from '../../redux/actions';
import { connect } from 'react-redux';


function ApplyJob({applyJob}) {
  const {loading, currentUser} = useSelector(state => state.user)

  if(loading) {
    return <PreLoader />
  }

  if (!currentUser?.is_onboarded) {
    <Navigate to='/onboarding/welcome' />
  }

  const handleSubmit = () => {
    let newJob = {
      job_application: 
        {
          job_id: 7,
          first_name: "Uduak",
          last_name: "Essien",
          email: "acushla4real@gmail.com",
          phone: "08030000000",
          resume: "https://drive.google.com/file/d/1kfunvQDEGA_R3011I9dwDSaTH43D8GRb/view",
          cover_letter: "https://drive.google.com/file/d/1kfunvQDEGA_R3011I9dwDSaTH43D8GRb/view"
      }
      }
      applyJob(newJob,'job')
      console.log(newJob)
  }


  return (
    <DashboardLayout>
        <div className='max-w-7xl mx-auto'>
          <div className='text-right mb-2'>
            <Button variant='normal'>Post new Gig</Button>
          </div>
            
            <div className='float-left bg-white hidden md:w-[30%] md:block rounded-md p-4 text-center'>
                <div className='w-full flex justify-center mt-4'>
                  <img src={farmCartImg} alt="farm cart" className='text-center' />
                </div>
                <hr className='mt-4 mb-1' />
                <h2 className='text-lg text-[#4E5D78]'>Farm Cart</h2>
                <p className='text-sm text-[#A4ADBC]'>Melbourne, AU</p>

                <div className='mt-2 text-sm leading-10'>
                  <div className='flex justify-between'>
                    <span>Posted</span>
                    <span>1 day ago</span>
                  </div>
                  <div className='flex justify-between'>
                    <span>Application Rank</span>
                    <span>25</span>
                  </div>
                </div>
            </div>

            <div className='float-right bg-white w-full md:w-[68%] rounded-md p-4'>
                <div className='space-x-2 text-[12px] font-light'>
                  <Button variant='normal' outline='true'>Jobs</Button>
                  <Button variant='normal' active={true}>Gigs</Button>
                  <Button variant='normal' outline='true'>Remote</Button>
                  <Button variant='normal' outline='true'>Date Posted</Button>
                  <Button variant='normal' outline='true'>Job Length</Button>
                </div>
                <div className='space-x-4 text-[12px] font-light mt-4 flex'>
                  <div>
                    <h4 className='-mb-4'>Category</h4>
                    <Button variant='normal' active={true}>Product Design</Button>
                  </div>
                  <div>
                    <h4 className='-mb-4'>Location</h4>
                    <Button variant='normal' active={true}>Lagos, Nigeria</Button>
                  </div>
                  <div>
                    <h4 className='-mb-4'>Recruiter</h4>
                    <Button variant='normal' active={true}>Mike Okafor</Button>
                  </div>
                  <div>
                    <h4 className='-mb-4'>Company</h4>
                    <Button variant='normal' active={true}>RM Talent Solutions</Button>
                  </div>
                </div>
                <div className='space-x-2 text-[10px] font-light mt-6'>
                  <div className='inline-flex items-center bg-[#EBF2FF] p-1 rounded-md'>
                    <img src={timeImg} alt="time" />
                    <span className='ml-1'>Hours needed: Less than 30 hrs</span>
                  </div>
                  <div className='inline-flex items-center bg-[#EBF2FF] p-1 rounded-md'>
                    <img src={timeImg} alt="time" />
                    <span className='ml-1'>Duration: Less than 1 month</span>
                  </div>
                  <div className='inline-flex items-center bg-[#EBF2FF] p-1 rounded-md'>
                    <img src={bagImg} alt="time" />
                    <span className='ml-1'>Needed: Skill acquired Level</span>
                  </div>
                </div>

                <div className='my-4 leading-7'>
                  <h2 className='text-xl text-[#4E5D78]'>UX/UI Intern</h2>
                  <p className='text-[10px]'>Remote <span class="text-green-600">48 minutes</span> ago 13 applicants</p>
                </div>

                <div className='text-[#9aa4b4] leading-8'>
                  <h2 className='text-md mb-2'>About this Role</h2>
                  <p className='text-sm font-light'>You will join a fast-growing team of UX / Product Experience Designers to help our mission to elevate core experiences across the game, app, and web.<br />
                  As a UX (Product Experience) designer you are responsible for experience designs in and around games that are both complex and ambiguous. You will work within a cross-functional Agile team and across platforms to create in-depth end-to-end product experiences that have a deep impact on our users and are aligned with business needs.<br />
                  You will work closely and collaboratively with other teams, stakeholders, and designers across the organization to achieve these goals.</p>
                </div>

                <div className='flex items-center space-x-4 mt-8'>
                  <h4>Skill required</h4>
                  <span className='bg-[#EBF2FF] text-[#1B44FE] text-sm py-2 px-4 rounded-full'>
                    Product Design
                  </span>
                  <span className='bg-[#EBF2FF] text-[#1B44FE] text-sm py-2 px-4 rounded-full'>
                    Figma
                  </span>
                  <span className='bg-[#EBF2FF] text-[#1B44FE] text-sm py-2 px-4 rounded-full'>
                    Adobe XD
                  </span>
                </div>
                <div className='flex items-center space-x-3 my-4'>
                  <img src={bagBigImg} alt="bigBag" />
                  <span className='text-[#4E5D78]'>
                    $50/hr - $55/hr
                  </span>
                </div>

                <Button variant='normal' onClick={handleSubmit} className="mb-5">
                  Apply Now
                </Button>
            </div>

            <div className='float-right bg-white w-full md:w-[68%] rounded-md p-8 my-5'>
              <h4 className='mb-4'>Similar Jobs</h4>
              <div  className='flex flex-col sm:flex-row space-x-4'>

              <div className='flex justify-between flex-col w-[300px] rounded-md overflow-hidden shadow-md'>
                <div>
                  <img src={jobImg} alt="ui/ux" className='rounded-tr-md rounded-tl-md w-full' />
                </div>
                <div className="mt-4 p-4 flex space-x-4 items-center">
                  <img src={coyLogo} alt="logo" />
                  <div className='leading-2'>
                    <h4 className='-mb-2'>UI/UX Intern</h4>
                    <span className='text-[#0049D7] text-[12px]'>FlutterWave Inc</span>
                  </div>
                </div>
                <p className='text-[10px] text-[#B0B7C3] px-4 pb-4'>You will work closely and collaboratively with other teams, stakeholders, and designers across the organization to achieve these goals</p>
              </div>

              <div className='flex justify-between flex-col w-[300px] rounded-md overflow-hidden shadow-md'>
                <div>
                  <img src={jobImg2} alt="ui/ux" className='rounded-tr-md rounded-tl-md w-full' />
                </div>
                <div className="mt-4 p-4 flex space-x-4 items-center">
                  <img src={coyLogo2} alt="logo" />
                  <div>
                    <h4 className='-mb-2'>UI/UX Intern</h4>
                    <span className='text-[#0049D7] text-[12px]'>FlutterWave Inc</span>
                  </div>
                </div>
                <p className='text-[10px] text-[#B0B7C3] px-4 pb-4'>You will work closely and collaboratively with other teams, stakeholders, and designers across the organization to achieve these goals</p>
              </div>

              </div>
            </div>
        </div>
    </DashboardLayout>
  )
}

const mapStateToProps = state => ({
  jobs: state.job,
})

const mapDispatchToProps = (dispatch) => ({
  applyJob: (job,jobtype) => dispatch(applyJob(job, jobtype)),
})

export default connect(mapStateToProps,mapDispatchToProps)(ApplyJob)