/* eslint-disable react-hooks/exhaustive-deps */
import OnboardLayout from '../../components/layout/onboard';
import { useNavigate } from 'react-router-dom';

import NextPrev from '../../components/next-prev';

const styles = {
  container: "max-w-6xl mx-auto bg-white px-16 py-10 rounded-lg shadow-sm flex flex-col mb-8",
  buttonSelect: 'mt-4 shadow-lg flex w-[60%] justify-between items-center py-4 px-8 hover:bg-blue-500 hover:text-white rounded-lg cursor-pointer transition-all duration-300 mb-2',
};

export default function Job() {
  const navigate = useNavigate();
  
  return (
    <OnboardLayout>
      <NextPrev next='/dashboard' prev='/onboarding/education' />

      <div className={styles.container}>
        <h1 className="text-3xl font-bold mb-2 ">Would you like to post an opening on Jobex?</h1>
        <p>Quickly create an open role on jobex</p>

        <h4 className="block text-gray-700 text-md font-semibold mt-8 text-left" >Select an option</h4>

        <div className='w-full sm:w-[80%] mb-10'>
          <button
            onClick={() => navigate('/jobs/new/job')}
            className='mt-4 bg-[#1B44FE] w-full block text-left h-[3.5rem] cursor-pointer text-white font-light py-2 px-4 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed'>
            Post Job
          </button>

          <button
            onClick={() => navigate('/jobs/new/gig')} 
            className='mt-4 bg-[#B3E5FC] w-full block text-left h-[3.5rem] cursor-pointer  font-light py-2 px-4 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed'>
            Post Gig
          </button>
        </div>
      </div>

      <div className="h-12" />
    </OnboardLayout>
  );
}