import { useState } from "react";
import PostModal from "../posts/modal";
import { createPost } from "../../redux/actions";
import { connect } from "react-redux";
import { UserPhoto } from "../../utils/userPhoto";

function DashboardPost({ userInfo={}, makePost }) {
  const [isOpen, setIsOpen] = useState(false);

  const cleanupModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="bg-white p-4 mr-4 rounded-lg mb-4">
      <h2>Share an update</h2>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center my-4 cursor-pointer"
      >
        <UserPhoto
          photo={userInfo.photo}
          altText={userInfo.name}
          slug={userInfo.slug}
          size="small"
        />
        <span className="text-gray-300 ml-2">Write your thoughts</span>
      </div>
      <hr className="border border-gray-50 my-6" />
      <div className="flex justify-end">
        <button className="bg-[#EDEDED] px-3 py-1 text-sm rounded-md mr-3">
          Preview
        </button>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-[#EDEDED] px-3 py-1 text-sm rounded-md"
        >
          Post status
        </button>
      </div>
      {isOpen && (
        <PostModal
          onSubmit={(userPost) => {
            makePost(userPost);
          }}
          user={userInfo}
          onClose={cleanupModal}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userData: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  makePost: (post) => dispatch(createPost(post)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPost);
