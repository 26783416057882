const settings = {
  api: {
    uri: "http://localhost:3001/api/v1",
  },
  meta: {
    rootUrl: "http://localhost:3000",
    title: "Jobex",
    description: "Jobex allows newbies without experience to find jobs",
    social: {
      graphic:
        "https://res.cloudinary.com/acushlakoncepts/image/upload/v1650498631/logo_ygd5tp.png",
      twitter: "@jobex",
    },
  },
  routes: {
    authenticated: {
      pathAfterFailure: "/login",
    },
    public: {
      pathAfterFailure: "/documents",
    },
  },
};

export default settings;