import React from "react";
import DashboardLayout from "../../components/layout/dashboard";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import JobDetails from "../../components/job-details";
import Button from "../../components/button";
import PreLoader from "../../components/loader";
import logo from "../../images/logo.png"
import { FaAngleDown } from "react-icons/fa";

function FormOne() {
  const { loading, currentUser } = useSelector((state) => state.user);
  const { allJobs } = useSelector((state) => state.job);
  const navigate = useNavigate();
  const { job_id } = useParams();

  const job = allJobs.find((job) => parseInt(job.id) === parseInt(job_id));

  if (loading) {
    return <PreLoader />;
  }

  if (!currentUser?.is_onboarded) {
    <Navigate to="/onboarding/welcome" />;
  }

  const verifyAndNext = () => {
    navigate('/jobs/apply/form-two')
  }

  return (
    <DashboardLayout>
      <div className="max-w-6xl mx-auto flex justify-between mb-8">
        <JobDetails job={job}/>
        <div className="relative flex flex-col px-10 shadow-sm rounded-lg py-10 bg-white basis-[49%]">
          <div>
            <h2 className="text-[#4E5D78] text-[24px]">Your profile</h2>
            <p className="text-[#4E5D78] text-[15px] mt-3">please enter the updated information about yourself</p>
            <p className="text-[#4E5D78] text-[15px] mt-3">Apply with</p>
            <div className="flex mt-4 items-center">
              <p>Easy apply</p>
              <div className="px-3 py-1 ml-3 rounded border-[1px] border-[#1B44FE]">
              <img className="h-[32px] w-[auto]" src={logo} alt='jobex logo'/>
              </div>
            </div>
            <h3 className="text-[#525252] text-[20px] mt-5">Manual Application</h3>
          </div>
          <form className="flex flex-col">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <div className="flex flex-col">
                <label className="mt-4 text-[#464646]" htmlFor="first_name">
                First Name<span className="ml-1 text-[#F50000]">*</span>
              </label>
                <input
                  className="mt-1 flex basis-[48%] px-4 py-2 rounded focus:outline-none text-[#2d2d2d] border-[1px] border-[#A0A0A0]"
                  type="text"
                  id="first_name"
                  name="first_name"
                />
                </div>
                <div className="flex flex-col">
                <label className="mt-4 text-[#464646]" htmlFor="Last_name">
                Last name<span className="ml-1 text-[#F50000]">*</span>
              </label>
              <input
                  className="mt-1 px-4 flex basis-[48%] py-2 rounded focus:outline-none text-[#2d2d2d] border-[1px] border-[#A0A0A0]"
                  type="text"
                  id="Last_name"
                  name="Last_name"
                />
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div id="start_date" className="flex justify-between">
                <div className="flex flex-col">
                <label className="mt-2 text-[#464646]" htmlFor="pry_email">
                Primary Email<span className="ml-1 text-[#F50000]">*</span>
              </label>
                <input
                  className="mt-1 flex basis-[48%] px-4 py-2 rounded focus:outline-none text-[#2d2d2d] border-[1px] border-[#A0A0A0]"
                  type="email"
                  id="pry_email"
                  name="email"
                  placeholder="email"
                />
                </div>
                <div className="flex flex-col">
                <label className="mt-2 text-[#464646]" htmlFor="start_date">
                Phone Number<span className="ml-1 text-[#F50000]">*</span>
              </label>
              <input
                  className="mt-1 px-4 flex basis-[48%] py-2 rounded focus:outline-none text-[#2d2d2d] border-[1px] border-[#A0A0A0]"
                  type="tel"
                  id="phone_number"
                  name="phone"
                  placeholder="Phone Number"
                />
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <label className="mt-6 text-[#464646]" htmlFor="school_skills">
                Skills Acquired<span className="ml-1 text-[#F50000]">*</span>
              </label>
              <div className="px-4 basis-[48%] text-[#2d2d2d] flex items-center py-2 rounded mt-1 border-[1px] border-[#A0A0A0]">
              <input
                className="focus:outline-none w-full"
                type="text"
                id="user_skills"
                name="skills"
                placeholder="skills"
              />
              <FaAngleDown className="mx-2 text-[#B0B7C3] text-[20px]" />
                </div>
            </div>

            <div className="flex flex-col">
              <label className="mt-4 text-[#464646]" htmlFor="additional_question">
                Additional Questions
              </label>
              <textarea
                className="mt-1 mb-[50px] px-4 py-2 h-[150px] rounded focus:outline-none text-[#2d2d2d] border-[1px] border-[#A0A0A0]"
                type="text"
                id="additional_question"
                name="question"
                placeholder="question"
              />
            </div>
            <div className="absolute flex margin-auto translate-x-[-50%] left-[50%] justify-center gap-x-3 bottom-[40px]">
              <Button
                variant="normal"
                className="rounded justify-center flex items-center mt-4 w-[200px]"
                onClick={verifyAndNext}
              >
                <span className="text-[14px]">Next</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default FormOne;
