import confirmImg from '../../images/success.png'
import Button from "../button"


export default function AccountConfirm({title, message, btnText, handleClick}) {
  return (
    <>
      <div className="my-4">
        <img src={confirmImg} alt="Confirm account"  />
      </div>
      <h1 className="text-3xl font-medium py-4">{title}</h1>
      {message && <p className="text-lg mb-4">{message}</p>}
      <Button handleClick={handleClick}>{btnText}</Button>
    </>
  )
}