import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../components/layout/dashboard'
import { Navigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaSearch, FaMapMarkerAlt, FaAngleDown } from "react-icons/fa";
import Button from '../../components/button'
import PreLoader from '../../components/loader';
import { connect } from 'react-redux';
import { getGigs, getJobs } from '../../redux/actions';


const searchJobType = [
  {
    id: "full-stack",
    name: "full-stack job",
    value: "full-stack",
  },
  {
    id: "part-time",
    name: "part-time job",
    value: "part-time",
  },
  {
    id: "Remote",
    name: "Remote job",
    value: "Remote",
  },
];

const searchJobCategory = [
  {
    id: "all",
    name: "All job",
    value: "all",
  },
  {
    id: "sales",
    name: "Sales job",
    value: "sales",
  },
  {
    id: "design",
    name: "Design jobs",
    value: "design",
  },
  {
    id: "product",
    name: "product jobs",
    value: "product",
  },
  {
    id: "developer",
    name: "Developer jobs",
    value: "developer",
  },
];

const randomKey = () => Math.random().toString(36).slice(2)

const ListItems = ({items}) => (
  <ul className='mt-4'>
  {items.map(({ id, name, value }) => (
    <li key={randomKey()} className="flex items-center mb-[10px]">
      <input
        className="h-[18px] w-[18px]"
        type="checkbox"
        id={id}
        name={name}
        value={value}
      />
      <label className="ml-[14px] text-[12px]" htmlFor={id}>
        {name}
      </label>
      <span className="text-[9px] py-1 px-3 bg-[#EBF2FF] rounded-full text-[#1B44FE] ml-2">
        { Math.floor(Math.random() * 100) }
       </span>
    </li>
  ))}
</ul>
)


const JobList = ({ jobs }) => {
  return (
    <div className="bg-[#F6F6F8] p-6 rounded-lg">
      {jobs?.map(({ title, company_name, timeago, work_model, id }) => (
        <div key={randomKey()} className="flex justify-between mb-8 items-center hover:bg-white hover:shadow-md p-3 rounded-lg transition-all delay-150 cursor-pointer">
          <div className='flex flex-row w-1/2'>
            <img src={"https://picsum.photos/id/237/200/300"} alt={title} className="w-14 h-14 rounded-md mr-10" />
            <div>
              <h3 className='text-xl text-[#3A3A3A]'>{title}</h3>
              <p className='flex items-center flex-row'>
                <span className="text-[#0049D7] font-light">{company_name}</span>
                <span className='text-[#929292] text-[11px] ml-2 font-extralight'> Posted {timeago}</span>
              </p>
            </div>
          </div>

          <di className='flex justify-between w-1/2 pl-10'>
            <div>
              <span className="py-1 px-5 rounded-3xl bg-[#EBF2FF] text-[#1B44FE] font-light text-[10px]">{work_model}</span>
            </div>

            {/* <Button variant='normal' className="-mt-1"> */}
            <Link 
               to={`/jobs/${id}/apply`}
               className='py-2 px-4 -mt-1 text-center cursor-pointer text-white bg-[#1B44FE] hover:bg-[#1b45fee5] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed'
            >
              <span className='text-[11px]'>Apply Now</span>
            </Link>
            {/* </Button> */}
          </di>

        </div>
      ))}
    </div>
  );
}


function Jobs({allJobs, allGigs, getJobs, getGigs}) {
  const {loading, currentUser} = useSelector(state => state.user)
  const [displayLocation, setDisplayLocation] = useState(false);
  const [displayJob, setDisplayJob] = useState(false);
  const jobs = allJobs
  const gigs = allGigs
  useEffect(() => getJobs(), []);
  useEffect(() => getGigs(), []);
  if(loading) {
    return <PreLoader />
  }

  if (!currentUser?.is_onboarded) {
    <Navigate to='/onboarding/welcome' />
  }
  return (
    <DashboardLayout>
        <div className="max-w-6xl mx-auto px-16 pt-5 pb-10 flex flex-col">
          <div className="flex justify-between items-center mb-6 flex-wrap">
            <h1 className='text-2xl font-bold'>Lets find you a new job/gig</h1>
            <Button variant='normal'>Post a new Job</Button>
          </div>
        </div>
        <div className="max-w-6xl mx-auto bg-white px-10 py-10 rounded-lg shadow-sm flex flex-col mb-8">
          <div className="flex justify-center -mt-[65px]">
            <div className="basis-[40%]">
              <div className="flex items-center justify-between bg-white shadow-lg h-[50px] rounded-tl-md rounded-bl-md">
                <div className="text-[#B0B7C3] flex ml-2 items-center">
                  <FaSearch className='mx-2' />
                  <input
                    className="outline-none w-full"
                    // onClick={() => setDisplayJob(true)}
                    type="text"
                    placeholder="Search jobs"
                  />
                </div>
                <FaAngleDown className='mx-2 text-[#B0B7C3]' />
              </div>
              <ul style={{ display: `${displayJob ? "block" : "none"}` }}>
                {jobs?.map(({ name }) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </div>

            <div div className="basis-[60%] mr-5">
              <div className="flex items-center justify-between bg-white shadow-lg h-[50px] rounded-tr-md rounded-br-md">
                <div className="text-[#B0B7C3] flex ml-2 items-center">
                  <FaMapMarkerAlt className='mx-2' />
                  <input
                    className="outline-none w-[90%]"
                    // onClick={() => setDisplayLocation(true)}
                    type="text"
                    placeholder="Select location"
                  />
                </div>
                <div className='-mt-5 mr-2'>
                  <Button variant='normal'>
                    <span className="text-sm">search</span>
                  </Button>
                </div>
              </div>

              <ul style={{ display: `${displayLocation ? "block" : "none"}` }}>
                {jobs?.map(({ location, category }) => (
                  <li key={category}>{location}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex flex-col pt-8 gap-12">
            <div className="flex">
              <div className="basis-[25%]">
                <div>
                  <h3 className="text-[#C0C0C0]">Type of employment</h3>
                  <ListItems items={searchJobType} />
                </div>
                <div className="mt-8">
                  <h3 className="text-[#C0C0C0]">category</h3>
                  <ListItems items={searchJobCategory} />
                </div>
              </div>

              <div className="w-full">
                <div>
                  <h3>Showing: <span className='font-bold'>{jobs.length} filtered jobs</span></h3>
                  <JobList
                    jobs={jobs}
                    searchJobType={searchJobType}
                  />
                </div>
                <div className="mt-12">
                  <h3>Showing: <span className='font-bold'>{gigs.length} filtered gigs</span></h3>
                  <JobList
                    jobs={gigs}
                    searchJobType={searchJobType}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
    </DashboardLayout>
  )
}

const mapStateToProps = state => ({
  allJobs: state.job.allJobs,
  allGigs: state.job.allGigs,
})

const mapDispatchToProps = (dispatch) => ({
  getJobs: () => dispatch(getJobs()),
  getGigs: () => dispatch(getGigs()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Jobs)
