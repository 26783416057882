import React from 'react';
import {
  BrowserRouter, Routes, Route
} from 'react-router-dom';
import App from './pages/App';
import Dashboard from './pages/Dashboard';
import About from './pages/onboarding/about';
import Skills from './pages/onboarding/skills';
import Welcome from './pages/onboarding/welcome';
import Profile from './pages/profile';
import Register from './pages/Register';
import ThankYou from './pages/ThankYou';
import VerifyEmail from './pages/VerifyEmail';
import ProtectedRoutes from './containers/proctected-routes';
import Education from './pages/onboarding/education';
import Job from './pages/onboarding/job';
import Jobs from './pages/jobs';
import ApplyJob from './pages/jobs/apply';
import FormOne from './pages/jobs/formOne';
import FormTwo from './pages/jobs/formTwo';
import RecentJobSearch from './pages/jobs/recent-job-search';
import NewJob from './pages/jobs/new';

const MyRoutes = ({logout}) => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/onboarding/welcome" element={<ProtectedRoutes component={Welcome} />} />
      <Route exact path="/onboarding/about" element={<ProtectedRoutes component={About} />} />
      <Route exact path="/onboarding/skills" element={<ProtectedRoutes component={Skills} />} />
      <Route exact path="/onboarding/education" element={<ProtectedRoutes component={Education} />} />
      <Route exact path="/onboarding/job" element={<ProtectedRoutes component={Job} />} />
      <Route exact path="/thank-you" element={<ThankYou />} />
      <Route exact path="/verify-email" element={<VerifyEmail />} />
      <Route exact path="/dashboard" element={<ProtectedRoutes component={Dashboard} />} />
      <Route exact path="/jobs" element={<ProtectedRoutes component={Jobs} />} />
      <Route exact path="/jobs/:job_id/apply" element={<ProtectedRoutes component={FormOne} />} />
      <Route exact path="/jobs/apply/form-one" element={<ProtectedRoutes component={FormOne} />} />
      <Route exact path="/jobs/apply/form-two" element={<ProtectedRoutes component={FormTwo} />} />
      <Route exact path="/jobs/recent-job-search" element={<ProtectedRoutes component={RecentJobSearch} />} />
      <Route exact path="/jobs/new/:type" element={<ProtectedRoutes component={NewJob} />} />
      <Route exact path="/users/:slug" element={<ProtectedRoutes component={Profile} />} />
    </Routes>
  </BrowserRouter>
);

export default MyRoutes;