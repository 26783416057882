import RegisterForm from "../components/register-form";
import AuthContainer from "../containers/auth";

function Register() {
  return (
    <AuthContainer>
      <RegisterForm />
    </AuthContainer>
  );
}

export default Register;
