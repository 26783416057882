import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PreLoader from '../../components/loader'

function ProtectedRoutes({ component: Component }, props) {
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector(state => state.user.currentUser) 

  const navigate = useNavigate();

  useEffect(() => {
    // TODO: Check for expired token as well
    if (currentUser) {
      setLoading(false);
      return;
    }
    navigate("/");
  }, []);

  return (
    <>
    {loading ? 
      <PreLoader /> : 
      <Component {...props} />} 
    </>
  )
}

export default ProtectedRoutes;
