/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Button from '../../components/button';
import OnboardLayout from '../../components/layout/onboard';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { updateUser } from '../../redux/actions';
import { connect } from 'react-redux';

import arrow from "../../images/arrow-right.svg";
import NextPrev from '../../components/next-prev';

const styles = {
  container:
    "max-w-6xl mx-auto bg-white px-16 py-10 rounded-lg shadow-sm flex flex-col mb-8",
  buttonSelect: 'mt-4 shadow-lg flex w-[60%] justify-between items-center py-4 px-8 hover:bg-blue-500 hover:text-white rounded-lg cursor-pointer transition-all duration-300 mb-2',
  skillsBtn: 'mr-4 py-1 px-3 rounded-md border  text-sm',
};

const DEFAULT_EDU = {
  school: '',
  degree: '',
  course: '',
  start_year: '',
  end_year: '',
  description: '',
}

// TODO: Add validation

function Education({ updateUser, userData }) {
  const [languages, setLanguages] = useState([]);
  const [education, setEducation] = useState([]);
  const [singleEdu, setSingleEdu] = useState(DEFAULT_EDU);
  const navigate = useNavigate();
 
  const handleInput = (e) => {
    if(e.key === ',') {
      const value = e.target.value
      setLanguages([...languages, value.substring(0, value.length - 1)])
      e.target.value = ''
    }
  }

  useEffect(() => {
    if (userData?.currentUser) {
      setLanguages(userData.currentUser.language)
      setEducation(userData.currentUser.education)
    }
  }, [])

  const handleUpdate = () => {
    if(!isRequiredEdu){
      setEducation([...education, singleEdu])
      setSingleEdu(DEFAULT_EDU)
    }

    const userLanguage = {
      language: languages,
      is_onboarded: true,
      education,
    }

    updateUser(userLanguage)
    navigate('/onboarding/job')
  }

  const isRequiredEdu = singleEdu.school === '' || singleEdu.degree === '' || singleEdu.course === '' || 
  singleEdu.start_year === '' || singleEdu.end_year === '' || singleEdu.description === ''  

  return (
    <>
    <OnboardLayout>
      <NextPrev next='/onboarding/job' prev='/onboarding/skills' />

      <div className={styles.container}>
        <h1 className="text-3xl font-bold mb-2">What did you study?</h1>
        <p>As a recruiter, we want to  better understand your educational background.</p>
        <button
          className="flex justify-center rounded-sm border p-2 relative my-8  w-full">
          <div className="flex justify-between items-center">
            <p onClick={() => navigate('/onboarding/job')} className="ml-12 text-sm">I’m not a student </p>
          </div>
          <div>
            <img src={arrow} className="h-5 right-16 absolute" alt="arrow right" />
          </div>
        </button>
         <p className='mb-5 hr-lines'>Or share your educational history</p>
        <div className="mx-auto text-lg">
        </div>

        {/* Education Start */}
        <div>
          
          { education.length > 0 &&
            <>
            <h4>Existing Education</h4>
            {
            education?.map((edu, index) => (
              <div key={index} className="w-full mt-2 mb-4">
                <h2 
                  className='space-x-2'>
                  <span className='text-lg font-bold cursor-pointer'>0{index + 1}. </span> 
                  <span className='text-sm' onClick={() => setSingleEdu(education[index])}> Edit</span>
                  <span className='text-sm'> | </span>
                  <span className='text-sm text-red-700 cursor-pointer' onClick={() => setSingleEdu(education.splice(index, 1))}> Remove</span>
                </h2> 
                <hr />
                <div className='flex justify-between items-center flex-wrap space-x-4 space-y-2 mt-4'>
                <p><strong>School: </strong> {edu.school}</p>
                <p><strong>Degree: </strong> {edu.degree}</p>
                <p><strong>Course: </strong> {edu.course}</p>
                <p><strong>Year: </strong> {edu.start_year} - {edu.end_year}</p>
                <p><strong>Description: </strong> {edu.description}</p>
                </div>
              </div>
            ))
            }
            </>
          }
        </div>
        <div className='js-education' onBlur={() => {
          if(!isRequiredEdu){
            if(!_.isEqual(singleEdu, education[education.length - 1])){
              setEducation([...education, singleEdu])
            }
          }
        }}>
          <div className="mt-4 w-full">
            <label className='font-semibold mb-2' htmlFor='schoolInput'>School or Collage/university *</label>
            <input
              onChange={(e) => setSingleEdu({ ...singleEdu, school: e.target.value })}
              value={singleEdu.school}
              id='schoolInput'
              className='w-full p-[1rem] border border-gray-300 rounded-lg'
              placeholder="University of ..." 
            />
          </div>
          <div className="mt-4 w-full">
            <label className='font-semibold mb-2' htmlFor='degreeInput'>Degree *</label>
            <input
              onChange={(e) => setSingleEdu({ ...singleEdu, degree: e.target.value })}
              value={singleEdu.degree}
              name='degreeInput'
              className='w-full p-[1rem] border border-gray-300 rounded-lg'
              placeholder="B.Sc/Certificate ..." 
            />
          </div>
          <div className="mt-4 w-full">
            <label htmlFor='courseInput' className='font-semibold mb-2'>Course *</label>
            <input
              onChange={(e) => setSingleEdu({ ...singleEdu, course: e.target.value })}
              value={singleEdu.course}
              name='courseInput'
              className='w-full p-[1rem] border border-gray-300 rounded-lg'
              placeholder="University of ..." 
            />
          </div>
        
          <div className='flex w-full'>
            <div className="mt-4 w-full mr-2">
              <label htmlFor='yearInput' className='font-semibold mb-2'>Year *</label>
              <input
                onChange={(e) => setSingleEdu({ ...singleEdu, start_year: e.target.value })}
                value={singleEdu.start_year}
                name='yearInput'
                className='w-full p-[1rem] border border-gray-300 rounded-lg'
                placeholder="Start Year ..." 
              />
            </div>
            <div className="mt-4 w-full ml-2">
              <label htmlFor='endYear' className='font-semibold mb-2 text-white'>.</label>
              <input
                onChange={(e) => setSingleEdu({ ...singleEdu, end_year: e.target.value })}
                value={singleEdu.end_year}
                name='endYear'
                className='w-full p-[1rem] border border-gray-300 rounded-lg'
                placeholder="End Year" 
              />
            </div>
          </div>
          <div className="mt-4 w-full">
            <label htmlFor='desc' className='font-semibold mb-2'>Description</label>
            <textarea
              onChange={(e) => setSingleEdu({ ...singleEdu, description: e.target.value })}
              value={singleEdu.description}
              name='desc'
              rows={5}
              className='w-full p-[1rem] border border-gray-300 rounded-lg'
              placeholder="Description" 
            />
          </div>
        </div>

        {/* Education Ends */}
        <Button
          disabled={isRequiredEdu}
          handleClick={() => {
            if(!_.isEqual(singleEdu, education[education.length - 1])){
              setEducation([...education, singleEdu])
            }
            setSingleEdu(DEFAULT_EDU)
          }}
          className='mt-4 bg-[#1B44FE] max-w-[10rem] sm:w-[12rem] h-[3.5rem] text-center cursor-pointer text-white font-light py-2 px-4 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed'>
          Add More +
        </Button>


        <div className="mt-8 w-full ">
          <label htmlFor='langInput' className='text-left font-semibold mb-2'>Language </label>
          <p className='text-left text-gray-400 mb-1'>Use comma (,) to separate skills</p>
          <input
            onKeyUp={handleInput}
            name='langInput'
            className='w-full p-[1rem] border border-gray-300 rounded-lg'
            placeholder="English" />

          <div className='mt-4'>
            <div className='flex flex-row justify-start'>
              {
                languages?.map((language, index) => (
                  <span key={index} className={styles.skillsBtn} >
                    {language}
                    <button 
                      onClick={() => setLanguages(languages.filter(s => s !== language))}
                      className='ml-2 text-red-700'>x</button>
                  </span>
                ))
              }
              
            </div>
          </div>

        </div>

        <Button
          handleClick={handleUpdate}
        >
          Save and Continue
        </Button>
      </div>


      <div className="h-4" />
    </OnboardLayout>
    </>
  );
}

const mapStateToProps = state => ({
  userData: state.user,
})

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(updateUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Education);