import { UserPhoto } from "../../utils/userPhoto";

export default function CommentDisplay({comments={}}) {
  return (
    <>
    {
      comments.map(comment => (
        <div key={comment.id} className="flex items-start my-3 ml-4">
           <UserPhoto
                photo={comment.photo}
                altText={comment.owner}
                slug=''
                size='small'
              />
          <div className='bg-gray-100 p-2 flex flex-col ml-2 w-full'>
            <div className='flex justify-between'>
              <h5 className='font-semibold text-sm'>{comment.owner}</h5>
              <span className='text-[10px] text-gray-500'>{comment.timeago}</span>
            </div>
            <p className='mt-2'>{comment.content}</p>
          </div>
        </div>
      ))
    }
    </>
  )
}