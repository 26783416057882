import DashboardLayout from '../components/layout/dashboard';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import {UserPhoto} from '../utils/userPhoto'
import Skills from '../components/profile/skills';
import Education from '../components/profile/education';
import DashboardLeft from '../components/dashboard/left';
import Language from '../components/profile/language';
import coverImg from "../images/profile/cover_default.jpg";
import { connect } from 'react-redux';
import { userProfile, updateUser } from '../redux/actions';
import PreLoader from '../components/loader';
import Button from '../components/button';
import EditProfile from '../components/profile/edit-profile/modal';


function Profile({getUserProfile, clearError, userData, updateUser}) {
  const [isOpen, setIsOpen] = useState(false);

  const cleanupModal = () => {
    setIsOpen(false);
  }
  let { slug } = useParams();
  const {loading, userProfile } = userData;

  useEffect(() => {
    getUserProfile(slug);

  }, [slug])

  if(loading) {
    return <PreLoader />
  }

  const handleEditModalSubmit = (updatedProfile) => {
    updateUser(updatedProfile)
    cleanupModal()
    window.location.reload();
  }

  return (
    <DashboardLayout> 
      <div className='max-w-7xl mx-auto'>
        <div className='flex flex-col sm:flex-row'>

          <div className='w-full lg:w-[75%] mb-4'>

            <div className="bg-white mr-4 rounded-lg">
              <div 
                className='h-[15rem] relative rounded-t-lg' 
                style={{backgroundImage: `url(${userProfile?.cover_image || coverImg})` }}
              >
                <div className="absolute -bottom-10 left-10 flex items-center">
                <UserPhoto
                photo={userProfile?.photo}
                altText={userProfile?.owner}
                slug={userProfile?.slug}
                size='large'
              />
                </div>
              </div>

              <div className="p-8 pt-0">

                <div className="flex justify-end gap-x-5">
                  <Button
                    variant="normal"
                    className="rounded justify-center my-5 flex items-center px-4 py-3"
                    onClick={() => setIsOpen(!isOpen)} 
                  >
                  <span className="text-[14px]">Edit profile</span>
                </Button>
                <Button
                  variant="normal"
                  className="rounded justify-center my-5 flex items-center px-4 py-3"
                >
                <span className="text-[14px]">Manage jobs</span>
              </Button>
              { isOpen &&
                <EditProfile
                  onSubmit={(profile) => {
                    handleEditModalSubmit(profile);
                  }}
                  user={userProfile}
                  onClose={cleanupModal}
                />
              }
                </div>

                <div className="flex justify-between">

                  <div>
                    <h5 className="font-semibold">{userProfile?.full_name}</h5>
                    <p className="text-gray-500 text-sm">{userProfile?.title}</p>
                  </div>

                  <div className="flex">
                    <div className="flex flex-col mr-10 items-center">
                      <span className="text-gray-500 text-sm font-bold">{userProfile?.followers}</span>
                      <span className="text-gray-500 text-[10px]">Followers</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="text-gray-500 text-sm font-bold">85</span>
                      <span className="text-gray-500 text-[10px]">Views</span>
                    </div>
                  </div>

                </div>
                <hr className="my-5" />
                <h4 className="uppercase font-semibold text-xl my-3 text-gray-500">About</h4>
                <p className="text-gray-500">{userProfile?.bio}</p>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row md:w-[100%] px-4 mx-auto items-top justify-center">
              <div className="bg-white px-8 py-4 mr-4 mt-4 rounded-lg min-w-[50%]">
                <Skills skills={userProfile?.skills} user={{}} />
              </div>

              <div className="bg-white px-8 py-4 mr-4 mt-4 rounded-lg  min-w-[50%]">
                <Education education={userProfile?.education} slug={slug} />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row md:w-[100%] mx-auto items-top">
             <Language language={userProfile?.language} />
            </div>

          </div>

          <div className='w-full lg:w-[25%] hidden lg:block'>
            <DashboardLeft />
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

const mapStateToProps = (state) => ({
  userData: state.user
})

const mapDispatchToProps = (dispatch) => ({
  getUserProfile: slug => dispatch(userProfile(slug)),
  clearError: () => dispatch({ type: 'CLEAR_ERROR' }),
  updateUser: data => dispatch(updateUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
