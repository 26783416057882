const PostActionTypes = {
  GET_POSTS_START: 'GET_POSTS_START',
  GET_POSTS_SUCCESS: 'GET_POSTS_SUCCESS',
  GET_POSTS_FAILURE: 'GET_POSTS_FAILURE',
  CREATE_POSTS_START: 'CREATE_POSTS_START',
  CREATE_POSTS_SUCCESS: 'CREATE_POSTS_SUCCESS',
  CREATE_POSTS_FAILURE: 'CREATE_POSTS_FAILURE',
  LIKE_POST_START: 'LIKE_POST_START',
  LIKE_POST_SUCCESS: 'LIKE_POST_SUCCESS',
  LIKE_POST_FAILURE: 'LIKE_POST_FAILURE',
  COMMENT_POST_START: 'COMMENT_POST_START',
  COMMENT_POST_SUCCESS: 'COMMENT_POST_SUCCESS',
  COMMENT_POST_FAILURE: 'COMMENT_POST_FAILURE',
};

export default PostActionTypes;
