import { toast } from "react-toastify"

export default async function ImageUploader(image) {
  const data = new FormData()
  data.append('file', image)
  data.append('upload_preset', 'oyi0yxwe')
  data.append('cloud_name', 'acushlakoncepts')

  try {
    const promise = fetch('https://api.cloudinary.com/v1_1/acushlakoncepts/image/upload', {
      method: 'post',
      body: data
    })
    
    const res = await toast.promise(
      promise, {
        pending: "Uploading image...",
        success: "Image uploaded successfully",
        error: "Error uploading image"
      }
    )

   return res.json()
    
  } catch (error) {
    console.error(error)
  }
}

