/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect} from 'react';
import Button from '../../components/button';
import OnboardLayout from '../../components/layout/onboard';
import _ from 'lodash';

import welcomeImg from "../../images/welcome.png";
import icon1 from "../../images/welcome-icon1.png";
import icon2 from "../../images/welcome-icon2.png";
import arrow from "../../images/arrow-right.svg";
import rarrow from '../../images/rarrow.svg'
import { updateUser } from '../../redux/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const styles = {
  container:
    "max-w-6xl mx-auto bg-white px-16 py-10 rounded-lg shadow-sm flex flex-col items-center text-center mb-8",
  buttonSelect: 'mt-4 shadow-lg flex w-[60%] justify-between items-center py-4 px-8 hover:bg-blue-500 hover:text-white rounded-lg cursor-pointer transition-all duration-300 mb-2',
  elseSelect: 'flex mt-6 text-xl border border-gray-50 hover:border-gray-200 py-1 px-2 rounded-md cursor-pointer'
};

function Welcome({ updateUser, userData }) {
  const [selected, setSelected] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.currentUser) {
      setSelected(userData.currentUser.job_status)
    }
  }, [])

  const handleUpdate = () => {

    if(_.isEqual(userData.currentUser.job_status, selected)) {
      navigate('/onboarding/about')
      return;
    }

    updateUser({ job_status: selected })
    navigate('/onboarding/about')
  }

  return (
      ( 
        <OnboardLayout>
        <div className={styles.container}>
          <h1 className="text-4xl font-bold mb-2">Welcome {userData?.currentUser.full_name} to Jobex </h1>
          <div className="max-w-2xl mx-auto text-lg">
            <p>
              Let&apos;s make a profile that helps you accomplish more
              proffesionally. We won&apos;t share your answerpublicly
            </p>
          </div>
          <div className="mt-4">
            <img src={welcomeImg} alt="welcome" />
          </div>
        </div>

        <div className={styles.container}>
          <h2 className="text-3xl font-bold mb-2">
            How would you like to advance your career?
          </h2>
          <h4 className="text-3xl my-2">This will help us guide you.</h4>

          <button
            onClick={() => setSelected('job-seeker')} 
            className={`${selected === 'job-seeker' && 'bg-blue-500 text-white'} ${styles.buttonSelect}`}>
            <div className="flex justify-between items-center">
              <img src={icon1} alt="icon1"  />
              <p className="ml-12 text-lg">Fine a new job</p>
            </div>
            <div>
              <img src={arrow} alt="arrow right" className='h-4' />
            </div>
          </button>

          <button
          onClick={() => setSelected('employer')}
          className={`${selected === 'employer' && 'bg-blue-500 text-white'} ${styles.buttonSelect}`}>
            <div className="flex justify-between items-center">
              <img src={icon2} alt="icon2" className='mr-6' />
              <p className="text-lg">Post available job openings </p>
            </div>
            <div>
              <img src={arrow} alt="arrow right" className='h-4' />
            </div>
          </button>


            <div 
              onClick={() => setSelected('others')}
              className={`${selected === 'others' && 'bg-blue-500 text-white'} ${styles.elseSelect}`}>
              <span className='mr-8'>I&apos;m here for something else </span>
              <img src={rarrow} alt="right arrow"  />
            </div>

          { selected ?
            <Button
            handleClick={handleUpdate}
            >
              Save and continue
            </Button> :
            <button
              disabled 
              className='max-w-[28rem] sm:w-[32rem] h-[4rem] mt-5 text-center text-white bg-gray-400 font-semibold py-2 px-4 rounded-lg'>
                Save and Continue
            </button>
          }
        </div>
        <div className="h-4" />
        </OnboardLayout> 
      )
  );
}

const mapStateToProps = state => ({
  userData: state.user,
})

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(updateUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);

