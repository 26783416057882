import { useState } from 'react';

import likeIcon from '../../images/post-icons/like.svg';
import commentIcon from '../../images/post-icons/comments.svg';
import shareIcon from '../../images/post-icons/shares.svg';
import dotsIcon from '../../images/post-icons/dots.svg';
import likeBlue from '../../images/post-icons/like-blue.svg';
import commentBlue from '../../images/post-icons/comment-blue.svg';
import shareBlue from '../../images/post-icons/share-blue.svg';
import { BiSend } from 'react-icons/bi';
import { UserPhoto } from '../../utils/userPhoto';
import CommentDisplay from '../comments/display';
import mysteryUser from "../../images/mystery.jpeg"
import { commentPost, likePost } from '../../redux/actions';
import { connect } from 'react-redux';



const styles = {
  postBtns: 'bg-[#EBF1FF] px-12 py-4 rounded-md hover:bg-blue-100 transition-all duration-300 active:shadow',
  postBtnsImg: 'flex items-center mr-1',
}

const POST_ICONS = [
  {
    icon: likeIcon,
    title: 'Like',
  },
  {
    icon: commentIcon,
    title: 'Comment',
  },
  {
    icon: shareIcon,
    title: 'Share',
  }
]

 
function PostCard({post={}, user={}, likePost, addComment}) {
  const [commentInput, setCommentInput] = useState('');
  const [isCommentInputOpen, setIsCommentInputOpen] = useState(false);


  const toggleCommentInput = () => {
    setIsCommentInputOpen(!isCommentInputOpen)
  }

  return (
  <>
    <div className='bg-white p-8 mr-4 rounded-lg mb-4'>
      <div className='flex justify-between my-4'>
        <div className='flex'>
        <UserPhoto
          photo={post?.owner.photo}
          altText={post?.owner.name}
          slug={post?.owner.slug}
          size='small'
          />
          <div className='ml-3 flex flex-col '>
            <span className='font-semibold text-sm'>{post?.owner.name || 'John Doe'}</span>
            <span className='text-gray-400 text-[10px]'>{post?.timeago || '1 min ago'}</span>
          </div>
        </div>
        <div className='flex items-center'>
            
          <button
            title='Like' 
            className='mr-1'>
            <img
              src={dotsIcon}
              className="h-6 w-6"
              alt="like"
            />
          </button>

        </div>
      </div>


      <p className='my-6 text-[#A7AEBC]'>
        {post?.content}
      </p>

      {/* Beginning of Post like, commnent and share icons */}
      <div className='flex justify-between mx-auto text-sm text-gray-700 mb-6'>
        <div className='flex items-center'>
          {
            POST_ICONS.map(({icon, title}) => (
              <button
                onClick={
                  title === 'Like' ?
                  () => likePost(post.id) :
                  title === 'Comment' ?
                  () => toggleCommentInput() :
                  () => console.log('comsharement')
                }
                key={title}
                title={title} 
                className='mr-1'>
                <img
                  src={icon}
                  className="h-6 w-6"
                  alt={title}
                />
              </button>
            ))
          }
          <div className="h-6 w-6 text-sm ml-2">2k</div>
        </div>

        <div className='text-gray-200'>
          <span className='ml-1'>{post?.comments_count || 0} Comments</span>
        </div>
      </div>
      {/* End of Post like, commnent and share icons */}

      <div className='flex justify-between mx-auto text-[12px] my-4'>
        
        <div className={styles.postBtns}>
          <button
            onClick={() => likePost(post.id)}
            title={`${post?.likes || 0} Likes`} 
            className={styles.postBtnsImg}>
            <img
              src={likeBlue}
              alt="likes"
            />
            <span className='ml-1'>{post?.likes} Likes</span>
          </button>
        </div>

        <div className={styles.postBtns}>
          <button
            onClick={() => toggleCommentInput()}
            title={`${post?.comments_count} Comments`} 
            className={styles.postBtnsImg}>
            <img
              src={commentBlue}
              alt="Comments"
            />
            <span className='ml-1'>Comments</span>
          </button>
        </div>

        <div className={styles.postBtns}>
          <button
            onClick={() => console.log("Shared!")}
            title="67 Shares" 
            className={styles.postBtnsImg}>
            <img
              src={shareBlue}
              alt="Shares"
            />
            <span className='ml-1'>Shares</span>
          </button>
        </div>

      </div>

      {/* Comments section */}
      <div className={isCommentInputOpen ? 'block' : 'hidden'}>
         <div className="flex flex-row items-center ml-auto relative">
         <UserPhoto
                photo={post?.owner.photo}
                altText={post?.owner.name}
                slug=''
                size='small'
              />
          <input 
            type="text"
            onChange={(e) => setCommentInput(e.target.value)}
            value={commentInput} 
            className="w-full ml-2 px-4 py-4 text-sm rounded-md text-gray-700 bg-gray-100 js-comment-inputbox" 
            placeholder="Write a comment..." 
          />
          <button
            onClick={() => {
              addComment(post.id, {comment: {content: commentInput}})
              setCommentInput('')
            }}
            className='bg-[#EBF1FF] p-2 rounded-full absolute right-2'>
            <BiSend color='#1B44FE' size={'1.5rem'} />
          </button>
         </div>

         <div>
            <CommentDisplay comments={post.comments} /> 
         </div>

      </div>
        {/* End of Comments section */}
    </div>
  </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  likePost: (postId) => dispatch(likePost(postId)),
  addComment: (postId, comment) => dispatch(commentPost(postId, comment)),
})

export default connect(null, mapDispatchToProps)(PostCard)