const JobActionTypes = {
    GET_JOBS_START: 'GET_JOBS_START',
    GET_JOBS_SUCCESS: 'GET_JOBS_SUCCESS',
    GET_JOBS_FAILURE: 'GET_JOBS_FAILURE',
    GET_GIGS_START: 'GET_GIGS_START',
    GET_GIGS_SUCCESS: 'GET_GIGS_SUCCESS',
    GET_GIGS_FAILURE: 'GET_GIGS_FAILURE',
    CREATE_JOB_GIG_START: 'CREATE_JOBS_GIGS_START',
    CREATE_JOB_GIG_SUCCESS: 'CREATE_JOBS_GIGS_SUCCESS',
    CREATE_JOB_GIG_FAILURE: 'CREATE_JOBS_GIGS_FAILURE',
    JOB_APPLY_START: 'JOB_APPLY_START',
    JOB_APPLY_SUCCESS: 'JOB_APPLY_SUCCESS',
    JOB_APPLY_FAILURE: 'JOB_APPLY_FAILURE',
    GET_MY_JOBS_START: 'GET_MY_JOBS_START',
    GET_MY_JOBS_SUCCESS: 'GET_MY_JOBS_SUCCESS',
    GET_MY_JOBS_FAILURE: 'GET_MY_JOBS_FAILURE',
    GET_MY_GIGS_START: 'GET_MY_GIGS_START',
    GET_MY_GIGS_SUCCESS: 'GET_MY_GIGS_SUCCESS',
    GET_MY_GIGS_FAILURE: 'GET_MY_GIGS_FAILURE',
    SUBMIT_STATUS:'SUBMIT_STATUS'
  };
  
  export default JobActionTypes;