/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Button from '../../components/button';
import OnboardLayout from '../../components/layout/onboard';
import { useNavigate } from 'react-router-dom';
import NextPrev from '../../components/next-prev';
import _ from 'lodash';

import { connect } from 'react-redux';
import { updateUser } from '../../redux/actions';


const styles = {
  container:
    "max-w-6xl mx-auto bg-white px-16 py-10 rounded-lg shadow-sm flex flex-col mb-8",
  buttonSelect: 'mt-4 shadow-lg flex w-[60%] justify-between items-center py-4 px-8 hover:bg-blue-500 hover:text-white rounded-lg cursor-pointer transition-all duration-300 mb-2',
};

function About({ updateUser, userData }) {
  const [about, setAbout] = useState('');
  const [title, setTitle] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.currentUser) {
      setAbout(userData.currentUser.bio)
      setTitle(userData.currentUser.title)
    }
  }, [])

  const handleUpdate = () => {
    const aboutInfo = {
      title,
      bio: about,
    }

    if(_.isEqual(userData.currentUser.title, title) && _.isEqual(userData.currentUser.bio, about)) {
      navigate('/onboarding/skills')
      return;
    }

    updateUser(aboutInfo)
    navigate('/onboarding/skills')
  }
  
  return (
    <OnboardLayout>
      <NextPrev next='/onboarding/skills' prev='/onboarding/welcome' />

    <div className={styles.container}>
      <h1 className="text-3xl font-bold mb-2 ">About Yourself </h1>
      <p>This will help users better understand who you are and what you do.</p>

  
      <div className="mt-4 w-full sm:w-[80%]">
      
        <label className="block text-gray-700 text-md font-semibold mb-2 text-left" >Title</label>
        <input
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          className='w-full p-[1rem] border border-gray-300 rounded-lg mb-4'
          placeholder="Product Designer" />
        <textarea
          onChange={(e) => setAbout(e.target.value)}
          value={about}
          className='w-full p-[1rem] border border-gray-300 rounded-lg h-52'
          name='about-info'
          maxLength={400}
          placeholder="Hi, my name is Jessy and I&apos;m a copywriter based in Detroit who specializes in helping clients large and small tell brand stories that stick. I earned my Bachelor&apos;s degree in Journalism from Indiana University and have more than 10 years of experience copywriting at startups and tech companies in the Bay Area and the Midwest. " />
        <span className='text-gray-300'>{400 - about.length}</span>
      </div>

      <Button
        handleClick={handleUpdate}
        disabled={!title || !about}
      >
        Continue
      </Button>
    </div>

    <div className="h-4" />
    </OnboardLayout>
  );
}


const mapStateToProps = state => ({
  userData: state.user,
})

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(updateUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(About);