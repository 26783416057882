
import React from "react";
import styles from "../../styles/toggleSwitch.module.css";
  
const ToggleSwitch = ({ handleToggle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.toggleSwitch} >
        <input type="checkbox" onClick={(e) => handleToggle(e.target.checked)} className={styles.checkbox}
               name='toggleSwitch' id='toggleSwitchid' />
        <label className={styles.label} htmlFor="toggleSwitchid">
          <span className={styles.inner} />
          <span className={styles.switch} />
        </label>
      </div>
    </div>
  );
};
  
export default ToggleSwitch;