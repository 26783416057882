import PostActionTypes from "./post.types";
import axios from "axios";
import { toast } from "react-toastify";

export const getPostsStart = () => ({
  type: PostActionTypes.GET_POSTS_START,
});

export const getPostsSuccess = (posts) => ({
  type: PostActionTypes.GET_POSTS_SUCCESS,
  payload: posts
});

export const getPostsFailure = (error) => ({
  type: PostActionTypes.GET_POSTS_FAILURE,
  payload: error
});

export const createPostStart = () => ({
  type: PostActionTypes.CREATE_POSTS_START,
});

export const createPostSuccess = (post) => ({
  type: PostActionTypes.CREATE_POSTS_SUCCESS,
  payload: post
});

export const createPostFailure = (error) => ({
  type: PostActionTypes.CREATE_POSTS_FAILURE,
  payload: error
});

export const likePostStart = () => ({
  type: PostActionTypes.LIKE_POST_START,
});

export const likePostSuccess = (id) => ({
  type: PostActionTypes.LIKE_POST_SUCCESS,
  payload: id
});

export const likePostFailure = (error) => ({
  type: PostActionTypes.LIKE_POST_FAILURE,
  payload: error
});

export const commentPostStart = () => ({
  type: PostActionTypes.COMMENT_POST_START,
});

export const commentPostSuccess = (comment) => ({
  type: PostActionTypes.COMMENT_POST_SUCCESS,
  payload: comment
});

export const commentPostFailure = (error) => ({
  type: PostActionTypes.COMMENT_POST_FAILURE,
  payload: error
});

// TODO: Account for error when API server is down
export const getPosts = () => function(dispatch) {
  dispatch(getPostsStart());
  axios
    .get("/posts")
    .then(res => {
      dispatch(getPostsSuccess(res.data));
    })
    .catch(err => {
      dispatch(getPostsFailure(err.response.data.error));
    });
};

// TODO: Setup real time updates for most recent posts

export const createPost = (data) => function(dispatch) {
  dispatch(createPostStart());
  axios
    .post("/posts", data)
    .then(res => {
      dispatch(createPostSuccess(res.data));
      dispatch(getPosts());
      toast.success("Post created successfully");
    })
    .catch(err => {
      if(err.response.status === 401) {
        dispatch(createPostFailure(err.response?.data?.error));
        toast.error(err.response?.data?.error);
      } else {
        dispatch(createPostFailure("Something went wrong"));
        toast.error("Something went wrong");
      }
    });
}

export const likePost = (id) => function(dispatch) {
  dispatch(likePostStart());
  axios
    .get(`/posts/${id}/likes`)
    .then(res => {
      dispatch(likePostSuccess(res.data));
      dispatch(getPosts());
      toast.success(res.data.message);
    })
    .catch(err => {
      if(err.response.status === 401) {
        toast.error(err.response?.data?.error);
        dispatch(likePostFailure(err.response?.data?.error));
      } else {
        toast.error("Something went wrong");
        dispatch(likePostFailure("Something went wrong"));
      }
    });
};

export const commentPost = (id, comment) => function(dispatch) {
  dispatch(commentPostStart());
  axios
    .post(`/posts/${id}/comments`, comment)
    .then(res => {
      toast.success("Comment posted successfully");
      dispatch(commentPostSuccess(res.data));
      dispatch(getPosts());
    })
    .catch(err => {
      if(err.response.status === 401) {
        toast.error(err.response?.data?.error);
        dispatch(commentPostFailure(err.response?.data?.error));
      } else {
        toast.error("Something went wrong");
        dispatch(commentPostFailure("Something went wrong"));
      }
    });
}
