import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import MyRoutes from './Routes';
import axios from 'axios';
import { getLocalStorageValue, URL } from './utils/constants';

axios.defaults.baseURL = URL
axios.defaults.headers.common.Authorization = `Bearer ${getLocalStorageValue()}`;
axios.defaults.mode = 'no-cors';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <MyRoutes />
      </PersistGate>
    </React.StrictMode>
  </Provider>
);

