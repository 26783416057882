import editIcon from '../../../images/profile/edit_icon.svg';
import { useState } from "react";
import EducationModal from './modal';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/actions'


export default function Education({education=[], openModal, slug}) {
  const [isOpen, setIsOpen] = useState(false);
  const [eduIndex, setEduIndex] = useState(null)
  const dispatch = useDispatch()

  const onClose = () => {
    setEduIndex(null)
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className="flex justify-between items-center flex-wrap mb-8">
        <h4 className="uppercase font-semibold text-xl text-gray-500 mb-3">Education</h4>
        <button
          onClick={() => setIsOpen(!isOpen)}  
          className="bg-[#1B44FE] px-4 py-3 text-white rounded-md"
        >
          Add Education
        </button>
      </div>
      {
        education?.map((edu, index) => (
          <div key={index} className={`flex items-center justify-between my-2 text-gray-500 pb-2 ${index+1 !== education.length && 'border-b-[1px]'}`}>
            <span>{`${edu.start_year}-${edu.end_year}:`}</span>
            <span>{edu.course}</span>
            <button
              onClick={() => {
                setEduIndex(index)
                setIsOpen(!isOpen)
              }}
              title="Edit" 
              className='mr-1'>
              <img
                src={editIcon}
                className="h-4 w-4"
                alt={'edit'}
              />
            </button>
          </div>
        ))
      }
      { isOpen &&
        <EducationModal
          user={{}}
          education={education}
          eduIndex={eduIndex}
          onClose={onClose}
          onSubmit={(edu, eduIndex=null, removeEdu=false) => {
            if (edu.length === 0) return;
            let newEducation = []

            if(eduIndex != null && eduIndex >= 0 ) {
              removeEdu ? education.splice(eduIndex, 1) : education[eduIndex] = edu
              newEducation = [...education]
            } else {
              newEducation = [...education, edu]
            }
            
            dispatch(updateUser({education: newEducation}, slug))
            onClose()
          }}
        />
      }
    </>
  )
}
