import React, { useState } from "react";
import DashboardLayout from "../../components/layout/dashboard";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import JobDetails from "../../components/job-details";
import Button from "../../components/button";
import PreLoader from "../../components/loader";
import { FaAngleDown } from "react-icons/fa";

function FormTwo() {
  const { loading, currentUser } = useSelector((state) => state.user);
  const [displayLocation, setDisplayLocation] = useState(false);
  const [displayJob, setDisplayJob] = useState(false);
  const navigate = useNavigate();

  if (loading) {
    return <PreLoader />;
  }

  if (!currentUser?.is_onboarded) {
    <Navigate to="/onboarding/welcome" />;
  }

  return (
    <DashboardLayout>
      <div className="max-w-6xl mx-auto flex justify-between mb-8">
        <JobDetails />
        <div className="relative flex flex-col px-10 shadow-sm rounded-lg py-10 bg-white basis-[49%]">
          <div>
            <h2 className="text-[#4E5D78] text-[24px]">Your profile</h2>
            <p className="text-[#4E5D78] text-[15px] mt-3">
              please enter the updated information about yourself
            </p>
            <h3 className="text-[#525252] text-[20px] mt-5">
              What did you study
            </h3>
            <p className="text-[#444444] text-[14px] mt-3">
              we want to better understand your background
            </p>
          </div>
          <form className="flex flex-col">
            <div className="flex flex-col">
              <label className="mt-4 text-[#464646]" htmlFor="school_input">
                School or College/University
              </label>
              <input
                className="mt-1 px-4 py-2 rounded focus:outline-none text-[#2d2d2d] border-[1px] border-[#A0A0A0]"
                type="text"
                id="school_input"
                name="university"
                placeholder="university"
              />
            </div>
            <div className="flex flex-col">
              <label className="mt-4 text-[#464646]" htmlFor="degree">
                degree
              </label>
              <div className="px-4 flex items-center text-[#2d2d2d] py-2 rounded mt-1 border-[1px] border-[#A0A0A0]">
                <input
                  className="focus:outline-none w-full"
                  type="text"
                  id="school_degree"
                  name="degree"
                  placeholder="degree"
                />
                <FaAngleDown className="mx-2 text-[#B0B7C3] text-[20px]" />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="mt-4 text-[#464646]" htmlFor="school_course">
                course/field
              </label>
              <input
                className="mt-1 px-4 py-2 rounded focus:outline-none text-[#2d2d2d] border-[1px] border-[#A0A0A0]"
                type="text"
                id="school_course"
                name="course"
                placeholder="course"
              />
            </div>
            <div className="flex flex-col">
              <label className="mt-4 text-[#464646]" htmlFor="start_date">
                Start Date*
              </label>
              <div id="start_date" className="flex justify-between">
                <div className="px-4 flex basis-[48%] text-[#2d2d2d] items-center py-2 rounded mt-1 border-[1px] border-[#A0A0A0]">
                  <input
                    className="w-full focus:outline-none"
                    type="text"
                    id="start_month"
                    name="year"
                    placeholder="month"
                  />
                  <FaAngleDown className="mx-2 text-[#B0B7C3] text-[20px]" />
                </div>
                <div className="px-4 basis-[48%] text-[#2d2d2d] flex items-center py-2 rounded mt-1 border-[1px] border-[#A0A0A0]">
                  <input
                    className="w-full focus:outline-none"
                    type="text"
                    id="start_year"
                    name="year"
                    placeholder="year"
                  />
                  <FaAngleDown className="mx-2 text-[#B0B7C3] text-[20px]" />
                </div>
              </div>
              <Button
                variant="normal"
                className="rounded justify-center flex items-center mt-4 w-[150px]"
              >
                <span className="text-[14px] mr-4">Add More</span>
                <span>+</span>
              </Button>
            </div>
            <div className="flex flex-col">
              <label className="mt-5 text-[#464646]" htmlFor="school_language">
                Language
              </label>
              <div className="px-4 basis-[48%] text-[#2d2d2d] flex items-center py-2 rounded mt-1 border-[1px] border-[#A0A0A0]">
              <input
                className="focus:outline-none w-full"
                type="text"
                id="school_language"
                name="language"
                placeholder="language"
              />
               <FaAngleDown className="mx-2 text-[#B0B7C3] text-[20px]" />
                </div>
            </div>
            <div className="absolute flex margin-auto translate-x-[-50%] left-[50%] justify-center gap-x-3 bottom-[10px]">
              <Button
                variant="normal"
                outline={true}
                className="rounded border-[#1B44FE] text-[#1B44FE] justify-center flex items-center mt-4 w-[100px]"
                onClick={() => navigate("/jobs/apply/form-one")}
              >
                <span className="text-[14px]">Back</span>
              </Button>
              <Button
                variant="normal"
                className="rounded justify-center flex items-center mt-4 w-[200px]"
              >
                <span className="text-[14px]">Confirm Application</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default FormTwo;
