const VARIANTS = {
  default: 'max-w-[28rem] sm:w-[32rem] py-4 px-4',
  normal: 'py-2 px-4'
}

export default function Button({
  children, 
  className, 
  variant='default', 
  outline=false, 
  handleClick,
  active,
  ...rest}) {
  return (
    <button
      onClick={handleClick}
      {...rest}
      className={`${VARIANTS[variant]} mt-5 text-center cursor-pointer text-white ${!outline ? 'bg-[#1B44FE] dark:bg-[#1B44FE]' : 'border border-[#C6C6C6] text-[#9A9A9A] hover:text-white'} hover:bg-[#1b45fee5] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed ${active && 'text-[#1B44FE] font-[400] bg-blue-50 border border-[#0066FF] hover:text-white'} ${className}`}
      >
      {children}
    </button>
  )
}
