import { useState } from "react";
import { Link, Navigate } from 'react-router-dom';
import Button from "../button";
import InputBox from "../input-box";
import { signUp } from "../../redux/actions";
import { connect } from "react-redux";

const defaultInfo = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
}

function RegisterForm({ signUp, userData, clearError }) {
  const [signupInfo, setSignupInfo] = useState(defaultInfo)
  const [hasAgreedTOS, setHasAgreedTOS] = useState(false);
  const [hasAgreedPrivacy, setHasAgreedPrivacy] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupInfo({
      ...signupInfo,
      [name]: value.trim()
    })
  }

  const handleSignUp = (e) => {
    e.preventDefault();
    signUp({user: signupInfo});
  }

  const _createFormState = (isDisabled = false, message = "") => ({isDisabled, message});

  const createFormState = ({first_name, last_name, email, password, confirm_password}, hasAgreedTOS, hasAgreedPrivacy) => {
    if( first_name?.length === 0 || 
        last_name?.length === 0 || 
        email?.length === 0 || 
        password?.length === 0 || 
        confirm_password?.length === 0
      ) {
        return _createFormState(true, "Please fill out all fields");
    }

    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')

    if(!strongPassword.test(password)) {
      return _createFormState(true, "Password must have at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character");
    }

    if(password !== confirm_password) {
      return _createFormState(true, "Passwords do not match");
    }

    if (!hasAgreedTOS) {
      return _createFormState(true, "You must agree to the Terms of Service");
    }

    if (!hasAgreedPrivacy) {
      return _createFormState(true, "You must agree to the Privacy Policy");
    }

    return _createFormState()
  } 

  const formState = createFormState(signupInfo, hasAgreedTOS, hasAgreedPrivacy);

  return userData.currentUser ?
    (
      <Navigate to="/dashboard" replace={true} />
    ) :
    (
    <div className="overflow-scroll">
    <h1 className='text-4xl font-semibold mb-5'>Sign up</h1>
        <p className='text-center text-xl text-[#191919]'>Welcome to Jobex, enter your details below</p>

        { userData.error &&
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex justify-between" role="alert">
          <span className="">{userData.error}</span>
          <span
            onClick={clearError}
            className="px-2">
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </span>
        </div>
        }

        <div className="flex max-w-[32rem] sm:w-[32rem]">
          <InputBox 
            onChange={handleChange}
            value={signupInfo.first_name}
            title="First name" placeholder="Enter your first name" name="first_name" />
          <div className="mx-1" />
          <InputBox 
            onChange={handleChange}
            value={signupInfo.last_name}
            title="Last name" placeholder="Enter your last name" name="last_name" />
        </div>
        <InputBox
          onChange={handleChange} 
          value={signupInfo.email}
          title="Email" placeholder="Enter your email" name="email" />

        <InputBox
          onChange={handleChange}
          value={signupInfo.password}
          title="Password" placeholder="Enter password" name="password" type="password" />
        <InputBox
          onChange={handleChange}
          value={signupInfo.confirm_password}
          title="Confirm password" placeholder="Confirm password" name="confirm_password" type="password" />

        <div className="max-w-[32rem] sm:w-[32rem] mt-5">
          <input
            checked={hasAgreedTOS}
            onChange={({target: {checked}}) => {
                setHasAgreedTOS(checked)
            }}
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#191919] checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
            type="checkbox" 
            value="" 
            id="flexCheckChecked" 
             />
          <label className="text-gray-800" htmlFor="flexCheckChecked">
          I agree to receive valuable emails from Jobex. We do not share any information with 3rd party and you have the option to opt out at anytime according to our Privacy Policy.
          </label>
        </div>

        <div className="max-w-[32rem] sm:w-[32rem] mt-5">
          <input
            checked={hasAgreedPrivacy}
            onChange={({target: {checked}}) => {
              setHasAgreedPrivacy(checked)
            }}
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#191919] checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckChecked" />
          <label className="text-gray-800" htmlFor="flexCheckChecked">
          I acknowledge that I have read and do hereby accept the terms and conditions in Jobex.
          </label>
        </div>

        { formState.message &&
          <div className="p-4 my-3 text-red-700 bg-red-200 rounded-lg text-sm">
            {formState.message}
          </div>
        }

          <Button
            disabled={formState.isDisabled}
            onClick={handleSignUp}
            className="cursor-disabled:not-allowed w-full"
          >
            Sign up
         </Button>

        <p className='mt-5 text-center text-xl text-[#191919]'>
          Already have an account? {' '} 
          <Link to='/' className='font-bold cursor-pointer'>
            Login
          </Link>
        </p>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userData: state.user
})

const mapDispatchToProps = (dispatch) => ({
  signUp: data => dispatch(signUp(data)),
  clearError: () => dispatch({ type: 'CLEAR_ERROR' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)