import { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Modal from '../../../components/modal';
import Button from '../../../components/button';


export default function SkillModal({user={}, onClose}) {
    const [isOpen, setIsOpen] = useState(false);
    const [skills, setSkills] = useState([]);

    useEffect(() => {
      if(!!user) {
        setIsOpen(true);
      }
    }, [])

    const handleInput = (e) => {
      if(e.key === ',') {
        const value = e.target.value
        setSkills([...skills, value.substring(0, value.length - 1)])
        e.target.value = ''
      }
    }

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    }

    const onSubmit = async (e) => {
      if (skills.length === 0) return;
      console.log(skills)
  
      // const userSkills = {
      //   skills
      // }
  
      // try {
      //   const promise = axios.put(`${URL}/users`, userSkills, axiosHeaderConfig);
      //   await toast.promise(
      //     promise, {
      //       pending: "Updating your skills...",
      //       success: "Update successfully",
      //       error: "Error updating skills",
      //     }
      //   )

      //   closeModal()
      // } catch (e) {
      //   console.log(e)
      //   console.log(e.response?.data)
      // }
    }

    return (
        <Modal isOpen={isOpen}>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between w-full">
                <h3 className="mb-5 text-lg font-bold leading-6 text-gray-900" id="modal-title">
                    Add Skill
                </h3>
                <button onClick={closeModal}>
                  <AiOutlineCloseCircle />
                </button>
              </div>
              <hr className="mb-5" />

              <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
               <p className='text-left font-semibold mb-2'>Most recent skills acquired *</p>
               <p className='text-left text-gray-400 mb-1'>Use comma (,) to separate skills</p>
                <input
                  onKeyUp={handleInput}
                  className='w-full p-[1rem] border border-gray-300 rounded-lg'
                  placeholder="UX Design" 
                />
                <div className='mt-4'>
                  <div className='flex flex-wrap'>
                    {
                      skills?.map((skill, index) => (
                        <span key={index} className='mr-4 py-1 px-3 rounded-md bg-[#B3E5FC] text-[#1B44FE] text-sm mb-2' >
                          {skill}
                          <button 
                            onClick={() => setSkills(skills.filter(s => s !== skill))}
                            className='ml-2 text-red-700'>x</button>
                        </span>
                      ))
                    }
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-col border-2 border-blue-100">
              <Button
              onClick={onSubmit}
              >Add</Button>
            </div>
      </div>
    </Modal>
    )
}