import lArrow from '../../images/svg/larrow.svg';
import rArrow from '../../images/svg/rarrow.svg';
import { useNavigate } from 'react-router-dom';


const NextPrev = ({next, prev}) => {
  const navigate = useNavigate();

  return (
    <div className='w-[90%] text-lg flex justify-between mx-auto mb-8 text-[#9C9C9C]'>
      <button 
        onClick={() => navigate({pathname: prev})}
        className='flex cursor-pointer'>
        <img src={lArrow} alt='lArrow' />
        <span className='ml-2'>Back</span>
      </button>
      <button
        onClick={() => navigate({pathname: next})}
        className='flex cursor-pointer'>
        <span className='mr-2'>Skip</span>
        <img src={rArrow} alt='lArrow' />
      </button>
    </div>
  )
}

export default NextPrev