import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RiHomeLine, RiUserAddLine, RiNotificationBadgeLine } from 'react-icons/ri';
import { BsBag } from 'react-icons/bs';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { signOut } from "../../redux/actions";

import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import logo from "../../images/logo.png";
import { UserPhoto } from "../../utils/userPhoto";



const MENU_LINKS = [
  {
    href: '/dashboard',
    title: 'Home',
    icon: RiHomeLine,
  },
  {
    href: '/#',
    title: 'Followers',
    icon: RiUserAddLine,
  },
  {
    href: '/jobs',
    title: 'Jobs/Gigs',
    icon: BsBag,
  },
  {
    href: '/#',
    title: 'Messages',
    icon: BiMessageSquareDetail,
  }
  ,
  {
    href: '/#',
    title: 'Notification',
    icon: RiNotificationBadgeLine,
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DashboardLayout ({children}) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.currentUser);

  const showUser = () => (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span className="sr-only">Open user menu</span>
          <div className="overflow-hidden">
          <UserPhoto
                photo={currentUser?.photo}
                altText={currentUser?.full_name}
                slug=''
                size='medium'
              />
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <Menu.Item>
            {({ active }) => (
              <>
                <Link 
                  to={`/users/${currentUser?.slug}`}
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-50')}
                  >
                  Your Profile
                </Link>
                
                <span
                  onClick={() => dispatch(signOut())}
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-50')}
                >
                  Logout
                </span>
            </>
            )}
          </Menu.Item>
          
        </Menu.Items>
      </Transition>
    </Menu>
  )

  return (
    <div className="h-full w-full sticky top-0 ">
      <div className="px-8 w-full h-18 py-2 bg-white flex items-center justify-between mb-8 flex-col md:flex-row">
        <div className="flex justify-between items-center">
          <img src={logo} alt="logo" />
          <div className="flex md:hidden">
            {showUser()}
          </div>
        </div>
        
        <div className="flex justify-between items-center my-4 md:my-0">

          <input type="text" placeholder="Search" className="bg-[#F8F8F8] pl-5 py-2 rounded-sm max-w-[12rem] mr-4 text-[#6F6F6F]" />

          { MENU_LINKS.map((link, index) => (
            <div key={index}>
             <Link to={link.href} className="flex justify-between items-center mx-2">
                <link.icon title={link.title} />
                <span className="ml-1 text-[14.5px] text-[#6F6F6F] hidden md:flex">
                  {link.title}
                </span>
             </Link>
            </div>
            ))
          }

        </div>
        <div className="hidden md:flex">
          {showUser()}
        </div>
      </div>
      {children}
      <div className="h-4" />
    </div>
  )
}

