import { useState } from "react";
import SkillModal from "./modal";

export default function Skills({skills=[]}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <h4 className="uppercase font-semibold text-xl text-gray-500 mb-3">Skills</h4>
        {/* <button
          onClick={() => setIsOpen(!isOpen)} 
          className="bg-[#1B44FE] px-4 py-3 text-white rounded-md">
            Add Skill
        </button> */}
      </div>
      {
        skills?.map((skill, index) => (
          <div key={index} className={`flex items-center my-2 text-gray-500 pb-2 ${index+1 !== skills.length && 'border-b-[1px]'}`}>
            {skill}
          </div>
        ))
      }
      { isOpen &&
        <SkillModal
          onSubmit={() => {
            // handlePost(userPost);
          }}
          user={{}}
          onClose={() => setIsOpen(false)}
          />
      }
    </>
  )
}