

export default function Language({language=[]}) {
  return (
    <div className="bg-white px-8 py-4 mr-4 mt-4 rounded-lg w-[48.5%]">
      <h4 className="uppercase font-semibold text-xl text-gray-500 mb-3">Language</h4>
      {
        language?.map((lang, index) => (
          <div key={index} className={`flex items-center justify-between my-2 text-gray-500 pb-2 ${index+1 !== language.length && 'border-b-[1px]'}`}>
            <span>{lang}</span>
          </div>
        ))
      }
    </div>
  )
}