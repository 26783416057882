/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Button from '../../components/button';
import OnboardLayout from '../../components/layout/onboard';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { updateUser } from '../../redux/actions';
import { connect } from 'react-redux';
import NextPrev from '../../components/next-prev';

const styles = {
  container:
    "max-w-6xl mx-auto bg-white px-16 py-10 rounded-lg shadow-sm flex flex-col mb-8",
  buttonSelect: 'mt-4 shadow-lg flex w-[60%] justify-between items-center py-4 px-8 hover:bg-blue-500 hover:text-white rounded-lg cursor-pointer transition-all duration-300 mb-2',
  skillsBtn: 'mr-4 py-1 px-3 rounded-md bg-[#B3E5FC] text-[#1B44FE] text-sm',
};

function Skills({ updateUser, userData }) {
  const [skills, setSkills] = useState([]);
  const navigate = useNavigate();
 
  const handleInput = (e) => {
    if(e.key === ',') {
      const value = e.target.value
      setSkills([...skills, value.substring(0, value.length - 1)])
      e.target.value = ''
    }
  }

  useEffect(() => {
    if (userData?.currentUser) {
      setSkills(userData.currentUser.skills)
    }
  }, [])

  const handleUpdate = () => {
    const userSkills = {
      skills
    }

    if(_.isEqual(userData.currentUser.skills, skills)) {
      navigate('/onboarding/education')
      return;
    }

    updateUser(userSkills)
    navigate('/onboarding/education')
  }

  return (
    <>
    <OnboardLayout>
      <NextPrev prev='/onboarding/about' next='/onboarding/education' />

      <div className={styles.container}>
        <h1 className="text-3xl font-bold mb-2">What&apos;s your acquired skills?</h1>
          <p> This will help recruiter  better understand your skill, even if you want to 
switch careers.</p>
        <div className="mt-4 w-full sm:w-[80%]">
        <p className='text-left font-semibold mb-2'>Skills Acquired *</p>
        <p className='text-left text-gray-400 mb-1 text-sm'>Use comma (,) to separate skills</p>
        <input
        onKeyUp={handleInput}
      className='w-full p-[1rem] border border-gray-300 rounded-lg'
      placeholder="UX Design" />

      <div className='mt-4'>
        <div className='flex flex-row justify-start'>
          {
            skills?.map((skill, index) => (
              <span key={index} className={styles.skillsBtn} >
                {skill}
                <button 
                  onClick={() => setSkills(skills.filter(s => s !== skill))}
                  className='ml-2 text-red-700'>x</button>
              </span>
            ))
          }
          
        </div>
      </div>

        </div>
        <Button
          handleClick={handleUpdate}
          disabled={!skills.length > 0}
        >
          Save and Continue
        </Button>
      </div>


      <div className="h-4" />
    </OnboardLayout>
    </>
  );
}

const mapStateToProps = state => ({
  userData: state.user,
})

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(updateUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Skills);