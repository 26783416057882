import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InputBox from "../input-box";
import Button from '../button';
import { connect } from 'react-redux';
import { signIn } from '../../redux/actions';
import { Navigate } from 'react-router-dom';


const styles = {
  forgotPassword: 'max-w-[32rem] sm:w-[32rem] cursor-pointer mt-2 flex justify-end items-end w-full',
}

function LoginForm({ signIn, userData, clearError }) {
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: ''
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  }

  const handleLogin = e => {
    e.preventDefault();
    signIn(userCredentials);
  }

  return userData.currentUser ? 
  (
    <Navigate to="/dashboard" replace={true} />
  ) :
  (
    <>
    <h1 className='text-4xl font-semibold mb-5'>Welcome back!</h1>
        <p className='text-center text-xl text-[#191919]'>Please enter email and password <br /> to log in into your account.</p>
        { userData.error &&
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex justify-between" role="alert">
          <span className="">{userData.error}</span>
          <span
            onClick={clearError}
            className="px-2">
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </span>
        </div>
        }

        <form onSubmit={handleLogin}>

          <InputBox title="Email" placeholder="Enter your email" name="email" value={userCredentials.email} onChange={handleInputChange} />
          <InputBox title="Password" placeholder="Enter password" name="password" type="password" value={userCredentials.password} onChange={handleInputChange} />

          <div className={styles.forgotPassword}>
            <p>Forgot password?</p>
          </div>
          
          <Button 
            onClick={handleLogin}
            disabled={userData.loading} 
            className='w-full'
          >
            {userData.loading ? 'Loading...' : 'Login'}
          </Button>

        </form>


        <p className='mt-5 text-center text-xl text-[#191919]'>
          Don&apos;t have an account yet? {' '}
          <Link to='/register' className='font-bold cursor-pointer'>
             Sign up
          </Link>
        </p>
    </>
  )
}

const mapStateToProps = (state) => ({
  userData: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  signIn: data => dispatch(signIn(data)),
  clearError: () => dispatch({ type: 'CLEAR_ERROR' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);