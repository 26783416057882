import Modal from "../modal";
import Button from "../button";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import congratImage from "../../images/cuate.png";
import { useNavigate } from "react-router-dom";

function SuccessModal({ message, onClose, background, btnStyle }) {
  const [isOpen, setIsOpen] = useState(true);

  const navigate = useNavigate();

  const closeModal = () => {
    onClose();
    setIsOpen(false)
    navigate("/dashboard");
  };


  return (
    <Modal isOpen={isOpen}>
      <div className="inline-block relative align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle sm:h-[35vh] sm:w-[20vw]">
        <div className="absolute top-3 right-3">
          <button
            onClick={closeModal}
            className="flex items-center justify-center h-6 w-6 rounded-[50%] bg-[#FFEDB4]"
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className={`${btnStyle} h-[55%] flex justify-center items-center rounded-t-lg`}>
          <img
            src={congratImage}
            alt="successful"
            className="w-[60%] h-[auto]"
          />
        </div>
        <div className="flex flex-col justify-center items-center h-[45%]">
          <h3 className="text-[1.2rem] ">Congratulation!</h3>
          <p className="mt-1 text-[.8rem]">{message}</p>
          <Button
            onClick={closeModal}
            variant="normal"
            className={`text-[#1B44FE] text-[.8rem] hover:bg-[#E8ECFF] rounded-sm px-6 mt-2 ${background}`}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default SuccessModal;
