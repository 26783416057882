import { useNavigate } from "react-router-dom";
import AccountConfirm from "../components/auth-confirm";
import AuthContainer from "../containers/auth";


export default function VerifyEmail() {
  const navigate = useNavigate();
  
  return (
    <AuthContainer>
        <AccountConfirm
        title="Account Confirmation"
        message="Please proceed to your email to confirm your account"
        btnText="Go to mail"
        handleClick={() => navigate('/')}
       />
    </AuthContainer>
  )
}

