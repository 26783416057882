import Button from "../../button";
import Modal from "../../modal";
import { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputBox from "../../input-box";

const defaultEducation = {
  school: '',
  degree: '',
  course: '',
  start_year: '',
  end_year: '',
  description: ''
}

export default function EducationModal({user={}, education=[], onClose, onSubmit, eduIndex}) {
  const [isOpen, setIsOpen] = useState(false);
  const [_education, setEducation] = useState(defaultEducation);
  
  const singleEducation = eduIndex >= 0 && education[eduIndex]

  useEffect(() => {
    if(!!user) {
      setIsOpen(true);
    }

    if (singleEducation) {
      _education.school = singleEducation.school
      _education.degree = singleEducation.degree
      _education.course = singleEducation.course
      _education.start_year = singleEducation.start_year
      _education.end_year = singleEducation.end_year
      _education.description = singleEducation.description
    }
  }, [])

  const closeModal = () => {
      setIsOpen(false);
      onClose();
  }

  const handleOnchange = e => (setEducation({
    ..._education,
    [e.target.name]: e.target.value
  }))


  return (
      <Modal isOpen={isOpen}>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-between w-full">
              <h3 className="mb-5 text-lg font-bold leading-6 text-[#4E5D78]" id="modal-title">
                  Add Education <br />
                  <span className="text-sm font-extralight">* indicates required</span>
              </h3>
              <button
                onClick={closeModal}
              >
                <AiOutlineCloseCircle />
              </button>
            </div>
            <hr className="mb-5" />

            <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left h-64 overflow-y-scroll">
              <InputBox 
                title="School" 
                placeholder="Ex:Boston University" 
                name="school" 
                variant="auto"
                value={_education?.school || ''}
                onChange={handleOnchange} 
                required />
              <InputBox 
                title="Degree" 
                placeholder="Ex:B.Sc" 
                name="degree" 
                variant="auto" 
                onChange={handleOnchange} 
                value={_education?.degree || ''}
                required />
              <InputBox 
                title="Field of Study" 
                placeholder="Ex:Business" 
                name="course" 
                variant="auto" 
                value={_education?.course || ''}
                onChange={handleOnchange} 
                required />
              <InputBox 
                title="Start Year" 
                placeholder="yyyy" 
                name="start_year" 
                variant="auto" 
                value={_education?.start_year || ''}
                onChange={handleOnchange}
                required />
              <InputBox 
                title="End Year" 
                placeholder="yyyy" 
                name="end_year" 
                variant="auto" 
                value={_education?.end_year || ''}
                onChange={handleOnchange} 
                required />
              <textarea
                onChange={handleOnchange} 
                className='w-full p-[1rem] border border-gray-300 rounded-lg h-40'
                name='description'
                value={_education?.description || ''}
                required
                placeholder="Description*" />

            </div>
          </div>

          <div className="px-4 py-3 sm:px-6 flex space-x-4 border-2 border-blue-100">
            <Button
              onClick={() => onSubmit(_education, eduIndex)}
              disabled={!(_education.school && _education.degree && _education.course &&
                _education.start_year && _education.end_year && _education.description)}
            >
              Add
            </Button>
            {singleEducation &&
              <Button
              outline={true}
              onClick={() => {
                const removeEdu = true
                onSubmit(_education, eduIndex, removeEdu)
              }}
              className='border-red-600'
              >
                Remove
              </Button>
            }
          </div>
    </div>
  </Modal>
  )
}