import React, { useState } from "react";
import DashboardLayout from "../../components/layout/dashboard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Button from "../../components/button";
import PreLoader from "../../components/loader";
import { AiOutlineClose } from "react-icons/ai";
import { FaAngleDown, FaMapMarkerAlt } from "react-icons/fa";
import { GiSettingsKnobs } from "react-icons/gi";
import ToggleSwitch from "../../components/toggle-switch";

const recentJob = [
  {
    id: uuidv4(),
    job_name: "content writer",
    company_name: "amazon books",
    country: "Nigeria",
  },
  {
    id: uuidv4(),
    job_name: "content developer",
    company_name: "microverse universe",
    country: "United States",
  },
  {
    id: uuidv4(),
    job_name: "software developer",
    company_name: "microverse universe",
    country: "london",
  },
];

const JobList = ({ jobs }) => {
  return (
    <div className="flex flex-col px-6 gap-y-5 rounded-lg">
      {jobs?.map(({ name, category, date, type_of_employment, icon }) => (
        <div
          key={uuidv4()}
          className="flex flex-col md:flex-row gap-y-4 w-full justify-between md:items-center hover:shadow-md p-3 rounded-lg transition-all delay-150 cursor-pointer"
        >
          <div className="flex flex-row md:w-1/2">
            <img src={icon} alt={name} className="w-14 h-14 rounded-md mr-4 md:mr-10" />
            <div>
              <h3 className="text-xl text-[#3A3A3A]">{name}</h3>
              <p className="flex items-center flex-row">
                <span className="text-[#0049D7] font-light">{category}</span>
                <span className="text-[#929292] text-[11px] ml-2 font-extralight">
                  {" "}
                  Posted {date}
                </span>
              </p>
            </div>
          </div>

          <div className="flex justify-between md:gap-x-[50px]">
            <div>
              <span className="py-1 px-5 rounded-3xl bg-[#EBF2FF] text-[#1B44FE] font-light text-[10px]">
                {type_of_employment}
              </span>
            </div>

            <Button variant="normal" className="-mt-1 px-8">
              <span className="text-[11px]">Apply Now</span>
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};


function RecentJobSearch() {
  const { loading, currentUser } = useSelector((state) => state.user);
  const [skills, setSkills] = useState(["skill"]);
  const [jobs, setJobs] = useState(["job"]);
  const [skillInputStat, setSkillInputStat] = useState(null);
  const [recentJobArr, setRecentJobArr] = useState(recentJob);
  const [dropRecentJob, setDropRecentJob] = useState(true);
  const [jobInputStat, setJobInputStat] = useState(null);
  const [toggleStat, setToggleStat] = useState(null);
  const navigate = useNavigate();

  const addSkills = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      let skill = event.target.value;
      event.target.value = "";
      setSkills([skill]);
      setSkillInputStat(false);
    }
  };

  const addJobs = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      let job = event.target.value;
      event.target.value = "";
      setJobs([job]);
      setJobInputStat(false);
    }
  };

  const clearAllSearch = () => {
    setJobInputStat(true);
    setSkillInputStat(true);
    setJobs([]);
    setSkills([]);
  };

  const clearJob = () => {
    setJobInputStat(true);
    setJobs([]);
  };

  const clearSkill = () => {
    setSkillInputStat(true);
    setSkills([]);
  };

  const clearRecentJob = () => {
    setRecentJobArr([]);
  };

  const jobsArr = [
    {
      name: "Fullstack dev",
      location: "london",
      category: "developer",
      type_of_employment: "Full-Time",
      icon: "https://picsum.photos/id/237/200/300",
      date: "3 days ago",
    },
    {
      name: "Video editing",
      location: "Nigeria",
      category: "design",
      type_of_employment: "Full-Time",
      icon: "https://picsum.photos/id/237/200/300",
      date: "2 days ago",
    },
    {
      name: "Content writing",
      location: "Nigeria",
      category: "writing",
      type_of_employment: "Part-Time",
      icon: "https://picsum.photos/id/237/200/300",
      date: "4 days ago",
    },
  ];

  const searchJobType = [
    {
      id: "full-stack",
      name: "full-stack job",
      value: "full-stack",
    },
    {
      id: "part-time",
      name: "part-time job",
      value: "part-time",
    },
    {
      id: "Remote",
      name: "Remote job",
      value: "Remote",
    },
  ];

  const removeSkill = (skill) => {
    let arr = skills.filter((s) => s !== skill);
    setSkills(arr);
  };

  const removeJob = (skill) => {
    let arr = jobs.filter((s) => s !== skill);
    setJobs(arr);
  };

  const handleToggle = (stat) => {
    setToggleStat(stat);
  };

  if (loading) {
    return <PreLoader />;
  }

  const Search = () => {
    return(
    <>
          <div className="md:flex hidden px-5 mb-4 items-center justify-between">
              <p>filters</p>
              <p
                onClick={clearAllSearch}
                className="cursor-pointer hover:text-[#8F8F8F] text:[#626262] text-[15px]"
              >
                clear all filters
              </p>
            </div>
            <hr className="w-full hidden md:flex my-6"></hr>
            <div className="px-5 hidden md:flex md:flex-col">
              <div className="flex items-center justify-between">
                <p>skills</p>
                <p
                  onClick={clearSkill}
                  className="cursor-pointer hover:text-[#8F8F8F] text:[#626262] text-[14px]"
                >
                  clear
                </p>
              </div>
              <div
                onClick={() => setSkillInputStat(true)}
                className="px-4 basis-[100%] text-[#2d2d2d] flex items-center py-2 rounded mt-4 border-[1px] border-[#A0A0A0]"
              >
                <input
                  className="focus:outline-none w-full"
                  type="text"
                  id="user_skills_search"
                  name="skills_search"
                  placeholder="skills_search"
                  style={{ display: skillInputStat ? "flex" : "none" }}
                  onKeyDown={(e) => addSkills(e)}
                  onBlur={() => setSkillInputStat(false)}
                />
                <div className="w-full h-[100%]">
                  <div
                    className={`${
                      skillInputStat ? "hidden" : "flex"
                    } w-full h-[100%]`}
                  >
                    {skills.map((skill, index) => (
                      <div
                        key={index}
                        className="px-2 py-1 text-[#ADADAD] rounded-sm text-xs bg-[#EAE8E8] flex gap-x-5 justify-between items-center border-[1px] border-[#A7A5A5]"
                      >
                        <p>{skill}</p>
                        <span
                          onClick={() => removeSkill(skill)}
                          className="cursor-pointer text-[11px]"
                        >
                          <AiOutlineClose />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <FaAngleDown className="mx-2 text-[#B0B7C3] text-[20px]" />
              </div>
            </div>
            <hr className="w-full md:flex hidden my-6"></hr>
            <div className="px-5 hidden md:flex md:flex-col mb-2">
              <div className="flex items-center justify-between">
                <p>job type</p>
                <p
                  onClick={clearJob}
                  className="cursor-pointer hover:text-[#8F8F8F] text:[#626262] text-[14px]"
                >
                  clear
                </p>
              </div>
              <div
                onClick={() => setJobInputStat(true)}
                className="px-4 basis-[100%] text-[#2d2d2d] flex items-center py-2 rounded mt-4 border-[1px] border-[#A0A0A0]"
              >
                <input
                  className="focus:outline-none w-full"
                  type="text"
                  id="user_jobs_search"
                  name="jobs_search"
                  placeholder="jobs_search"
                  style={{ display: jobInputStat ? "flex" : "none" }}
                  onKeyDown={(e) => addJobs(e)}
                  onBlur={() => setJobInputStat(false)}
                />
                <div className="w-full h-[100%]">
                  <div className={jobInputStat ? "hidden" : "flex"}>
                    {jobs.map((job, index) => (
                      <div
                        key={index}
                        className="px-2 py-1 text-[#ADADAD] rounded-sm text-xs bg-[#EAE8E8] flex gap-x-5 justify-between items-center border-[1px] border-[#A7A5A5]"
                      >
                        <p>{job}</p>
                        <span
                          onClick={() => removeJob(job)}
                          className="cursor-pointer text-[11px]"
                        >
                          <AiOutlineClose />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <FaAngleDown className="mx-2 text-[#B0B7C3] text-[20px]" />
              </div>
            </div>
            <div
                onClick={() => setJobInputStat(true)}
                className="px-4 basis-[100%] text-[#2d2d2d] flex md:hidden items-center py-2 rounded mt-1 border-[1px] border-[#A0A0A0]"
              >
                <input
                  className="focus:outline-none h-[100%] w-full"
                  type="text"
                  id="user_jobs_search"
                  name="jobs_search"
                  placeholder="jobs_search"
                  style={{ display: jobInputStat ? "flex" : "none" }}
                  onKeyDown={(e) => addJobs(e)}
                  onBlur={() => setJobInputStat(false)}
                />
                <div className="w-full h-[100%] flex items-center">
                  <div className={jobInputStat ? "hidden" : "flex"}>
                    {jobs.map((job, index) => (
                      <div
                        key={index}
                        className="px-2 py-1 text-[#ADADAD] rounded-sm text-xs bg-[#EAE8E8] flex gap-x-5 justify-between items-center border-[1px] border-[#A7A5A5]"
                      >
                        <p>{job}</p>
                        <span
                          onClick={() => removeJob(job)}
                          className="cursor-pointer text-[11px]"
                        >
                          <AiOutlineClose />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <GiSettingsKnobs className="mx-2 cursor-pointer rotate-90 text-[#1B44FE] text-[40px] h-fit" />
              </div>
            <div className="flex md:mt-[40px] justify-center w-full">
              <Button
                variant="normal"
                className="rounded w-full md:mx-auto md:w-[80%]"
                // onClick={}
              >
                search
              </Button>
            </div>
    </>
    )
}

  return (
    <DashboardLayout>
      <div className="max-w-6xl mx-auto flex mb-3 md:mb-8">
        <div className="flex justify-between w-full">
          <div className="hidden bg-white md:flex md:flex-col rounded-lg pt-5 pb-[40px] md:w-[30%] h-fit">
              <Search />
          </div>
          <div className="md:w-[68%] w-full flex flex-col gap-y-1 md:gap-y-5">
          <div className="flex flex-col rounded-lg bg-white md:bg-transparent gap-y-1 md:gap-y-5">
            <div className="flex px-6 gap-x-3 pt-5 pb-2 md:py-6 bg-white items-center md:rounded-lg">
              <ToggleSwitch handleToggle={handleToggle} />
              <p className="text-[9px] md:text-[15px] text-[#636363]">
                create a job alert and be the first to hear about jobs with no
                experience
              </p>
            </div>
            <div className="flex md:hidden px-6 flex-col pt-2">
              <Search />
            </div>
            <div className="bg-white pt-[50px] md:pt-6 md:rounded-lg">
              <div className="flex px-6 gap-x-3 items-center">
                <div className="flex w-full items-center justify-between">
                  <div className="flex gap-x-5 items-center">
                    <p className="font-medium">Recent Job Search</p>
                    <span
                      onClick={() => setDropRecentJob(!dropRecentJob)}
                      className="cursor-pointer text-[#9D9D9D]"
                    >
                      <FaAngleDown />
                    </span>
                  </div>
                  <button
                    disabled={recentJobArr.length === 0}
                    onClick={clearRecentJob}
                    className={`${
                      recentJobArr.length === 0
                        ? "text-[#9D9D9D]"
                        : "text:[#626262]"
                    } flex cursor-pointer items-center text-[17px] hover:text-[#9D9D9D] gap-x-1`}
                  >
                    <span className="flex text-[15px] items-center ">
                      <AiOutlineClose />
                    </span>
                    <p>clear</p>
                  </button>
                </div>
              </div>
              <hr className="w-full mt-2"></hr>
              <div
                className={`${
                  recentJobArr.length === 0 || !dropRecentJob
                    ? "hidden"
                    : "flex"
                } pb-6 px-6  mt-6 flex-col gap-y-5 md:gap-y-4 font-medium`}
              >
                {recentJobArr?.map(
                  ({ job_name, id, country, company_name }) => (
                    <div key={id}>
                      <h2 className="text-[15px] mb-2 md:mb-0 md:text-[20px]">{job_name}</h2>
                      <p className="text-[#0049D7] text-[12px]">
                        {company_name}
                      </p>
                      <div className="flex items-center gap-x-3 text-[12px] text-[#8B8B8B]">
                        <span>
                          <FaMapMarkerAlt />
                        </span>
                        <p>{country}</p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            </div>
            <div className="bg-white py-6 rounded-lg">
              <div className="px-6">
                <h2 className="text-20px font-medium">Remote opportunities</h2>
                <p className="text-[15px] mt-1 text-[#A1A1A1] font-medium">
                  All jobs does not require years of experience
                </p>
              </div>
              <hr className="w-full mt-2 mb-4"></hr>
              <JobList jobs={jobsArr} searchJobType={searchJobType} />
              <div className="px-6 w-full text-[#9C9C9C] text-[17px] flex justify-end ">
                <button className="px-8" type="button">
                  see more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default RecentJobSearch;
